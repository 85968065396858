import {
    GET_INN,
    CHANGE_ORGANIZATION_NAME,
    GET_INN_FROM_SERVER,
    CLEAR_EMPLOYEE_NAME,
    GET_EMPLOYEE_INN,
    GET_INN_FAILURE, PIN_CODE_CLEAR, GET_SESSIONID
} from './accountsActions';

const initialState = {
    name: '',
    sessionId: localStorage.getItem('SmartId-SessionId'),
    employeeName: '',
    innOrganization: '',
    employeeInn: '',
    userData: {},
    error: false,
    pin_message: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_SESSIONID:
            localStorage.setItem('SmartId-SessionId', action.sessionId);
            return {
                ...state,
                sessionId: action.sessionId
            };
        case GET_INN:
            return {
                ...state,
                name: action.name,
                innOrganization: action.payload,
                error: false
            }
        case GET_INN_FAILURE:
            return {
                ...state,
                [action.field]: action.payload,
                error: true,
            }
        case CHANGE_ORGANIZATION_NAME:
            return {
                ...state,
                name: ''
            }
        case GET_INN_FROM_SERVER:
            return {
                ...state,
                userData: action.payload
            }
        case CLEAR_EMPLOYEE_NAME:
            return {
                ...state,
                employeeName: '',
            }
        case GET_EMPLOYEE_INN:
            return {
                ...state,
                employeeName: action.payload,
                employeeInn: action.inn,
                error: false,
            }
        case PIN_CODE_CLEAR:
            return {
                ...state,
                error: false,
            }
        default:
            return state;
    }
};
