import React
  // ,
// {useState}
from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';
// import axios from '../../../common/api';
// import urls from '../../../common/urls';


const TaskModal = ({data,setOpen}) => {

  // const [comment,setComment] = useState('');
  //
  // const sendComment = () => {
  //   axios.put(`${urls.POST_COMMENT}/${data.id}`,{
  //     comment
  //   }).then(() => setOpen())
  //     .catch(e => console.error(e))
  // }

  return (
    <div>
      <Dialog
        open={data}
        onClose={setOpen}
      >
        <DialogTitle>Задача</DialogTitle>
        <DialogContent>
          {data && (
            <>
              <b>Назначил</b>
              <p>{data.creator}</p>
              <b>Исполнитель</b>
              <p>{data.implementer}</p>
              <b>Дата назначения</b>
              <p>{data.appointmentDate}</p>
              <b>Исполнить до</b>
              <p>{data.completionDate}</p>
              <b>Ф.И.О ребенка</b>
              <p>{data.childFio}</p>
              <b>Потребности ребенка, выявленые при проведении всесторонней оценки</b>
              <p>{data.requirenment}</p>
              <b>Мероприятия по предоставлению ребенку находящемуся в ТСЖ соответсвующей помощи и услуг</b>
              <p>{data.event}</p>
              <b>Ожидаемый результат</b>
              <p>{data.expectedResult}</p>
              {/*<b>Отметка об исполнении/неисполнении (прчина)</b>*/}
              {/*<TextField fullWidth style={{marginTop: '10px'}}/>*/}
              {/*<textarea value={comment} onChange={e => setComment(e.target.value)}/>*/}
            </>
          )}
        </DialogContent>
        <DialogActions className="second-modal">
          <Button className="second-modal__cancel" onClick={setOpen} color="default">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TaskModal;
