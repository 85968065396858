import React, {useEffect, useState} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import './index.scss';
import QuestionsFamily from "./QuestionsFamily";
import {ArrowLeftAnketaIcon} from "../../icons/icons";
import { getTableItemValue, getReportsDate, getSingleReport, updateActiveStep, postNewSchemeThunkWithImage, postEditedSchemaWithImage} from "../Table/mainTableActions";
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import {useHistory, useParams} from "react-router-dom";
import { Button, Divider } from '@material-ui/core';
import { links } from '../Table';
import ErrorBoundary from '../../components/error-boundary.js'
import axios from '../../common/api';
import url from "../../common/urls";
import {makeStyles, useMediaQuery} from '@material-ui/core'
// import { Alert, AlertTitle } from '@material-ui/lab';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: '100%',
//     },
//     button: {
//         marginTop: theme.spacing(1),
//         marginRight: theme.spacing(1),
//     },
//     actionsContainer: {
//         marginBottom: theme.spacing(2),
//     },
//     resetContainer: {
//         padding: theme.spacing(3),
//     },
// }));

function getSteps() {
    return [
        'Общие вопросы для ребенка и его семьи',
        'Ребенок с инвалидностью',
        'Информация о семье ребенке',
        'Юстиция для детей',
        'Социально бытовые вопросы','Образование ребенка',
        'Жестокое обращение (насилие) c ребенком', 'Медицинские и психологические вопросы',
        'Потребности ребенка', 'Детский труд (НФДТ)'
    ];
}

const useStyles = makeStyles({
    notCompletedStep: {
        '& .MuiSvgIcon-root': {
            // color: 'red'
        }
    },


})





export default function Questionnaire() {
    const styles = useStyles()
    // const [activeStep, setActiveStep] = React.useState(0);
    const activeStep = useSelector(state => state.mainPageReducer.activeStep);
    const questionare = useSelector(state => state.mainPageReducer.questionare);
    const selectedQuestionare = useSelector(state => state.mainPageReducer.selectedQuestionare);
    // const temp = useSelector(state => state.mainPageReducer.temp);
    const dispatch = useDispatch();
    const steps = getSteps();
    const history = useHistory();

    const { id, imageId } = useParams()
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState();
    const [imageSrc, setImageSrc] = useState();
    const [showExistImage, setShowExistImage] = useState(false)

  

    const [filledData,setFilledData] = useState({})
    console.log(questionare)

    const date = localStorage.getItem('time')
    const timeOfReport = localStorage.getItem('data')

    // console.log("%c selectedQuestionare",'color: green', selectedQuestionare)

    let changedFields = {};

    const changeField = (obj) => {
    // console.log("🚀 ~ file: questionnaire.js ~ line 61 ~ changeField ~ obj", obj)
      const temp = Object.keys(obj);
      changedFields[temp[0]] = obj[temp[0]];
      console.log('ASD', obj)
      setFilledData({...filledData,...changedFields})
    };
    console.log(changedFields)
    // if (id && questionare && selectedQuestionare) {
    //     changedFields = { ...questionare["model"], ...selectedQuestionare }
    // }
    // console.log("%c changedFields", 'color: orange',  changedFields)

    useEffect(() => {
        dispatch(getReportsDate('918bc9db-a779-415f-8b4e-47b978228b17'))
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (id && questionare && selectedQuestionare) {
            setFilledData({ ...questionare["model"], ...selectedQuestionare })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, selectedQuestionare])

    // console.log("%c questionare",'color: yellow', questionare)


    useEffect(() => {
        // setFilledData({
        //     ...questionare.model,
        // })
        if (id && date) {
            dispatch(getTableItemValue(id, date))
        } else if (id) {
            dispatch(getSingleReport(id));
        } 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, dispatch, timeOfReport ])

    useEffect(()=> {
        if (imageId) {
            axios.get(`${url.GET_IMAGE_OF_CHILD}/${id}`)
            .then((res) => {
                if (res.data) {
                    let src = "data:image/png;base64," + res.data;
                    setImageSrc(src)
                } else {
                    setShowExistImage(false)
                }
            })
            .catch((e) => console.error(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    
    if (id && questionare && questionare.length && selectedQuestionare && selectedQuestionare.length) {
        changedFields = {
            ...questionare.model,
            ...selectedQuestionare
        }
    }

    const updActiveStep = (step) => {
        dispatch(updateActiveStep(step))
    }

    const redirects = () => {
        history.push(`/table/${links[1].id}`);
      };

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!filledData['select-URRVEdr_Hjzbdu1O_DtS9'] || filledData['select-URRVEdr_Hjzbdu1O_DtS9'] === null) {
            alert('Заполните поле - Город областного значения')
            return
        }

        if (!filledData['select-aeozhPQSFy4YYLNlXUbJr'] || filledData['select-aeozhPQSFy4YYLNlXUbJr'] === null) {
            alert('Заполните поле - Город республиканского значения')
            return
        }

        const dataToSend = {
            ...questionare.model,
            ...filledData,
        }

        // let formdata = new FormData();
        // let formDataConverter = Object.entries(dataToSend)
        // formDataConverter.forEach(item => {
        //     formdata.append(item[0], item[1])
        // })

        // if (id && date) {
        //     redirects()
        //     return;
        // }

        if (id) {
            // dispatch(deleteChildImage(imageId))
            return dispatch(postEditedSchemaWithImage(id, '918bc9db-a779-415f-8b4e-47b978228b17', dataToSend, redirects, selectedFile, id, showExistImage))
        } else {
            dispatch(
                postNewSchemeThunkWithImage(
                    '918bc9db-a779-415f-8b4e-47b978228b17',
                    dataToSend,
                    redirects,
                    selectedFile
                )
              );
        }
        // if (id) {
        //     dispatch(deleteChildImage(imageId))
        // }
        // dispatch(
        //         postNewSchemeThunkWithImage(
        //             '918bc9db-a779-415f-8b4e-47b978228b17',
        //             dataToSend,
        //             redirects,
        //             selectedFile
        //         )
        //     );
    }

    const matches = useMediaQuery('(max-width: 900px)')

    return (
        <ErrorBoundary>
        <form onSubmit={handleSubmit} 
            method="post"
            encType="multipart/form-data"
            >
            <div className="stepper">
                <div className="stepper__wrap">
                    <div className="stepper__sidebar">
                        <div className="stepper__title">
                            <Box onClick={() => history.goBack()}>
                                <ArrowLeftAnketaIcon/>
                            </Box>
                            <div>{id ? "Анкета" : 'Новая анкета'} </div>
                        </div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step className={styles.notCompletedStep} onClick={()=> {updActiveStep(index)}} key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                            <Step className="special__divider" onClick={()=> {updActiveStep(10)}}>
                                <Divider orientation/>
                                <StepLabel >Анкетирование семьи</StepLabel>
                            </Step>
                        </Stepper>
                        <div>
                        <Button
                            className="create-modal__button create-modal__accept create-modal__accept_margin "
                            type="submit"
                        >
                            Сохранить
                        </Button>
                        </div>
                    </div>
                    <div className="stepper__content">
                        <Box onClick={() => history.goBack()}
                            style={{
                                margin: '20px 0 0',
                                display: matches ? 'block': 'none'
                            }}
                        >
                            <ArrowLeftAnketaIcon/>
                        </Box>

          

                        <QuestionsFamily changeField={changeField} filledData={filledData} steps={steps}
                                        selectedFile={selectedFile} setSelectedFile={setSelectedFile}
                                        preview={preview} setPreview={setPreview} id={id} imageId={imageId}
                                        imageSrc={imageSrc} showExistImage={showExistImage}
                                        setShowExistImage={setShowExistImage}
                        />
                    </div>
                    {/* <div className="alert-errors">
                        <Alert severity="error">
                            <AlertTitle>Ошибка</AlertTitle>
                            Вы заполнили поля неправильно
                        </Alert>
                    </div> */}
                </div>
            </div>
        </form>
        </ErrorBoundary>
    );
}