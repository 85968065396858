import React, {useCallback, useEffect, useState} from 'react';
import {TextField,Button} from '@material-ui/core';
import useStyles from './componentsStyle';

const OrganizationLoginForm = ({onSubmit,name,getInnThunk,clearInn,error}) => {

  const classes = useStyles();
  const [inn, setInn] = useState('');

  const getInnThunkCallback = useCallback(getInnThunk,[]);
  const clearInnCallback = useCallback(clearInn,[]);

  useEffect(() => {
    if (inn.length === 14) {
      return getInnThunkCallback(inn)
    }
    clearInnCallback();
  }, [inn, inn.length, getInnThunkCallback, clearInnCallback]);

  return (
    <form onSubmit={e => {
      console.log('The func has called')
      onSubmit(e,inn)
    }}>
      <p className={classes.label}>ИНН организации</p>
      <TextField
          autoFocus
          id="inn-input"
          type="text"
          variant="outlined"
          value={inn}
          inputProps={{
            maxLength: 14,
            minLength: 14,
          }}
          style={{maxWidth: '370px'}}
          inputStyle={{maxWidth: '370px'}}
          error={error}
          onChange={e => setInn(e.target.value)}
          helperText={`${name ? name : ' '}`}
        />
        <Button type='submit' disabled={(name && !name.length && !error)} className={classes.button} variant="contained" color="primary">
          Далее
        </Button>

    </form>
  )
}

export default OrganizationLoginForm;
