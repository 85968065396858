import {withStyles,InputBase} from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    height: '40px',
    paddingTop: '0',
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    padding: '10px 26px 10px 12px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
  },
}))(InputBase);

export default BootstrapInput;
