import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, CircularProgress } from "@material-ui/core";
import {
  getJsonThunk,
  clearStore,
  getFilterJsonThunk,
  closeSchemaModal,
  filterWindow,
  getSchemaThunk,
  filterFieldChange,
  sendFilterChoiceThunk,
  postChangedSchemaThunk,
  secondModalTrigger,
  getReportsDate,
  postNewSchemeThunk,
  updateActiveStep,
  SearchDbRecords,
  getJsonPagination,
} from "./mainTableActions";
import useStyles from "./style";
// import arrow from './assets/Arrow - Left.png';
// import filters from './assets/filters.png';
// import plus from './assets/plus.png';
import TableMainTable from "./components/table";
// import SearchInput from "./components/searchInput";
import ModalWindow from "./components/filterModal";
import SchemaModal from "./components/schemaModal";
import parseJson from "./common/json-parse";
import SecondModal from "./components/secondModal";
import notification from "../Main-page/assets/Notification.png";
import search from "../Main-page/assets/Search.png";
// import users from "../Main-page/assets/3 User.png";
import shield from "../Main-page/assets/Shield Done.png";
import danger from "../Main-page/assets/Danger.png";
import {
  AddRecordButtonIcon,
  ButtonSearchIcon,
  FilterIcon,
} from "../../icons/icons";
import Box from "@material-ui/core/Box";
import { Link, useParams } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

export const links = [
  {
    title: 'Создать новую запись в базе "Сигнал"',
    icon: notification,
    id: "274f0c5d-2c02-43b2-8d59-77e73b4494ea",
    description: "Краткое описание секции раздела меню",
    link: "/create/signal",
  },
  {
    title: "Выявление",
    icon: search,
    id: "918bc9db-a779-415f-8b4e-47b978228b17",
    description: "Краткое описание секции раздела меню",
    link: "/create/questionnaire",
  },
  {
    title: "ИПЗР/ПИРС",
    icon: shield,
    id: "7a868f4a-cded-49bf-8be2-1e0461423ebe",
    description: "Краткое описание секции раздела меню",
  },
  {
    title: "Дети в ТЖС",
    icon: danger,
    id: "9d7c9150-265f-42bc-b2ce-bb706d7408fc",
    description: "Краткое описание секции раздела меню",
  },
];
const DataTable = ({ history, match }) => {
  const classes = useStyles();
  const selector = useSelector((store) => store.mainPageReducer);
  const dispatch = useDispatch();
  const [tempData, setTempData] = useState([]);
  const [schemaId, setSchemaId] = useState(null);
  const [dataForTable, setDataForTable] = useState({
    headers: [],
    body: [],
    idies: [],
  });
  const pageObject = useSelector((store) => store.mainPageReducer.pageObject);
  const [page, setPage] = React.useState(1);
  // const [pageSize, setPageSize] = React.useState(10);
  const pageSize = 20;
  const handleChangePage = (event, value) => {
    setPage(value);
    console.log("tempdata", tempData);
  };

  const postNewScheme = (obj) =>
    dispatch(
      postNewSchemeThunk(match.params.id, {
        ...selector.schema.nativeLabels,
        ...obj,
      })
    );
  const getReportsByDate = () =>
    dispatch(getReportsDate(selector.activeSchemaId));
  const postChangeSchema = (obj) =>
    dispatch(postChangedSchemaThunk(schemaId, match.params.id, obj));
  const getSchema = (label, id) =>
    dispatch(getSchemaThunk(match.params.id, label, id));
  const closeSchema = useCallback(
    () => dispatch(closeSchemaModal()),
    [dispatch]
  );
  const cancelChoice = () => dispatch(filterWindow());
  const checkedChange = (arr) => {
    dispatch(filterFieldChange(arr));
    dispatch(
      sendFilterChoiceThunk(match.params.id, selector.filter.result.fields)
    );
  };
  const [input, setInput] = useState("");
  useEffect(() => {
    dispatch(getJsonThunk(match.params.id));
    return () => {
      dispatch(clearStore());
      dispatch(closeSchema());
    };
  }, [match.params.id, closeSchema, dispatch]);

  useEffect(() => {
    if (selector.data.models) {
      const {
        data: { models, headers },
      } = selector;
      const obj = parseJson(models, headers);
      setTempData(obj.models);

      setDataForTable({
        headers: obj.labels,
        body: obj.models,
        idies: selector.data.arrayOfId,
      });
    }
  }, [selector.loading, selector]);

  useEffect(() => {
    dispatch(getJsonPagination(match.params.id, page, pageSize));
  }, [page, dispatch, match.params.id]);

  // const search = (searchString) => {
  // if (searchString === '') {
  //     return setDataForTable({
  //         ...dataForTable,
  //         body: [...tempData]
  //     });
  // }
  // const temp = [];

  // tempData.map(bodyArray => {
  //     bodyArray.forEach(item => {
  //         if (searchString.toLowerCase() === item.toLowerCase().trim()) {
  //             temp.push(bodyArray);
  //         }
  //     })
  //     return null
  // });

  // setDataForTable({
  //     ...dataForTable,
  //     body: temp
  // });
  // };
  const formSubmit = async (e) => {
    // search(input);
    const data = {
      dbId: match.params.id,
      searchString: input,
    };
    await dispatch(SearchDbRecords(data));
    setInput("");
  };
  const { id } = useParams();
  let exist = id === links[1].id;
  console.log(selector.schemaOpen);

  const updateStep = () => {
    dispatch(updateActiveStep(0));
  };
  return (
    <Container style={{ padding: "0" }}>
      <ModalWindow
        checkedChange={checkedChange}
        cancel={cancelChoice}
        data={selector.filter}
        open={selector.filterOpen}
        classes={{ paper: classes.modalWindow }}
      />
      {id !== links[0].id ? (
        <SchemaModal
          title={links.map((item) =>
            item.id === match.params.id ? item.title : null
          )}
          activeId={selector.activeSchemaId}
          isIPZR={match.params.id === "7a868f4a-cded-49bf-8be2-1e0461423ebe"}
          openSecondModal={getReportsByDate}
          dates={selector.dates}
          postChange={selector.isNewScheme ? postNewScheme : postChangeSchema}
          close={closeSchema}
          open={!exist ? selector.schemaOpen : false}
          data={selector.schema}
          classes={{ paper: classes.modalWindow }}
        />
      ) : null}

      <SecondModal
        data={selector.secondModalData}
        close={() => dispatch(secondModalTrigger())}
        open={!exist ? selector.secondModalOpen : false}
        classes={{ paper: classes.modalWindow }}
      />

      {!(dataForTable.headers && dataForTable.headers.length) ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className='table'>
          <div className='table__top'>
            <div className='table__title'>
              {links.map((item) =>
                item.id === match.params.id ? item.title : null
              )}
            </div>
            <div className='table__button'>
              {/* {
                                !exist ? (
                                    <button onClick={() => getSchema(null, null)}>
                                                <AddRecordButtonIcon/>
                                                <span>Новая запись</span>
                                    </button>
                                ) : (
                                    <Link to='/create/questionnaire'>
                                        <button>
                                            <AddRecordButtonIcon/>
                                            <span>Новая запись</span>
                                        </button>
                                    </Link>
                                )
                            } */}
              {id === links[0].id ? (
                <Link to='/create/signal'>
                  <button>
                    <AddRecordButtonIcon />
                    <span>Новая запись</span>
                  </button>
                </Link>
              ) : null}
              {id === links[1].id ? (
                <Link to='/create/questionnaire' onClick={updateStep}>
                  <button>
                    <AddRecordButtonIcon />
                    <span>Новая запись</span>
                  </button>
                </Link>
              ) : null}
              {id === links[2].id /* || id === links[3].id */ ? (
                <button onClick={() => getSchema(null, null)}>
                  <AddRecordButtonIcon />
                  <span>Новая запись</span>
                </button>
              ) : null}
            </div>
          </div>
          <div className='table__content'>
            <div className='table__search'>
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                type='text'
                placeholder='Поиск по ФИО, ИНН и другим параметрам'
              />
              <button onClick={formSubmit}>
                <ButtonSearchIcon />
                <span>Поиск</span>
              </button>
              {/* <Box onClick={() => dispatch(getFilterJsonThunk(match.params.id))}>
                                <FilterIcon/>
                            </Box> */}
            </div>
            <TableMainTable
              setSchemaId={setSchemaId}
              activeId={selector.activeSchemaId}
              openSecondModal={getReportsByDate}
              loader={selector.schemaOpen}
              dates={selector.dates}
              getSchema={getSchema}
              body={dataForTable.body}
              models={selector.data.models}
              idies={dataForTable.idies}
              headers={dataForTable.headers}
            />
            <div className='paginations'>
              <Pagination
                count={pageObject.pageCount || 1}
                page={page}
                onChange={handleChangePage}
                variant='outlined'
                shape='rounded'
              />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default DataTable;
