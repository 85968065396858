export default {
  CHECK_INN_URL: '/api/auth/check',
  GET_TOKEN_URL: '/api/auth/pin/confirm',
  GET_EMPLOYEE_TOKEN_URL: '/api/auth/employee/pin/confirmNew',
  GET_DB_LIST_URL: '/api/db/list',
  GET_DB_LIST_NOT_NULL_SCHEMA_URL: '/api/Db/list/withoutemptyschema',
  CREATE_NEW_DB_URL: '/api/db/create',
  GET_SCHEMA_URL: '/api/db/get-schema',
  SAVE_CONSTRUCTOR_DATA_URL: '/api/db/create-schema',
  EDIT_EMPLOYEE_URL: '/api/employee/edit/',
  GET_EMPLOYEE_LIST_URL: '/api/employee/list',
  DELETE_EMPLOYEE_URL: '/api/employee/delete',
  ADD_NEW_EMPLOYEE_URL: '/api/employee/add',
  GET_EMPLOYEE_INFO_FOR_EDIT_URL: '/api/employee',
  CHECK_BOTH_INN_URL: '/api/auth/employee/check',
  GET_INN_INFO: '/api/employee/inn-info/',
  GET_PERSONAL_INN_INFO_URL: '/api/employee/check-user/',
  GET_DB_ACCESS_LIST_URL: '/api/db/access/list',
  CREATE_NEW_RECORD_IN_DB_URL: '/api/db/create-record',
  UPLOAD_CHILDREN_IMAGE: '/api/Db/upload-photo',
  EDIT_CHILDREN_IMAGE: '/api/Db/edit-photo',
  GET_IMAGE_OF_CHILD: '/api/Db/get-photo',
  DELETE_CHILD_IMAGE: '/api/Db/delete-photo',
  GET_DB_RECORDS_LIST_URL: '/api/db/record/list',
  SEND_TYNDYK_OUT_DATA_URL: '/api/tyndyk/add',
  GET_TYNDYK_DATA_LIST_URL: '/api/Tyndyk/list',
  GET_TYNDYK_DATA_FOR_EDIT_URL: '/api/Tyndyk/',
  EDIT_TYNDYK_ITEM_URL: '/api/Tyndyk/',
  DELETE_TYNDYK_ITEM_URL: '/api/Tyndyk/',
  SEND_EXCEL_FILE_URL: '/api/db/upload-file',
  WSDL_URL: '/api/',
  SEARCH_DB_RECORDS_URL: '/api/Db/search',
  DELETE_DB_URL: '/api/Db/delete/form/',
  GET_NAME_BY_TOKEN_URL: '/api/employee/info',
  EDIT_DB_NAME_URL: '/api/db/edit/form',
  GET_DB_LIST_FOR_NEW_EMPLOYEE_URL: '/api/db/list/denied-access',
  GET_MENU_LIST_URL: '/api/employee/menu',
  GET_DATABASE_LIST_URL: '/api/db/database/list',
  GET_CATALOG_LIST_URL: '/api/db/catalog/list',
  GET_CLASSIFIER_LIST_URL: '/api/db/classifier/list',
  SET_USER_FORM_SETTINGS_URL: '/api/db/userFormSettings',
  SET_USER_FORM_SETTINGS_URL_DETI: '/api/db/userFormSettingsDeti',
  POST_CHANGED_SCHEMA: '/api/Db/edit/record',
  GET_DATES: '/api/history/get-dates',
  GET_RECORD_HISTORY: '/api/history/get-record',
  GET_SCHEMA_HISTORY: '/api/db/get-schema',
  GET_DB_RECORD_ITEM: '/api/db/get-record',
  GET_CHILD_LIST: '/api/Issue/getChildList',
  POST_COMMENT: '/api/issue/edit',
  GET_MAIN_STATS: '/api/Statistic',
  GET_GROUPS_URL: '/api/Db/get-groups',
  GET_HISTORY_RECORDS: `/api/history/get-records-dates`
}
