import axios from '../../common/api';
import urls from '../../common/urls';

export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const OPEN_ONE_TASK = 'OPEN_ONE_TASK';
export const CLEAR_ONE_TASK = 'CLEAR_ONE_TASK';

const getTasksSuccess = payload => ({
  type: GET_TASKS_SUCCESS,
  payload
});

const getTasks = () => ({
  type: GET_TASKS
});

export const openOneTask = payload => ({
  type: OPEN_ONE_TASK,
  payload
});

export const clearOneTask = () => ({
  type: CLEAR_ONE_TASK
})

export const getTasksThunk = str => dispatch => {
  dispatch(getTasks());
  axios.get(`${urls.GET_CHILD_LIST}${str}`)
    .then(res => dispatch(getTasksSuccess(res.data.result)))
    .catch(e => console.error(e))
}
