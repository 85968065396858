import React, {useEffect, useRef, useState} from 'react';
import {Button, Dialog, DialogTitle} from '@material-ui/core';
import useStyles from "./componentsStyle";

const PinCodeForm = ({onSubmit, message, error, clearPin}) => {

  const classes = useStyles();
  const [pinCode, setPinCode] = useState(['','','','']);
  const [open, setOpen] = useState(true);
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirtyRef = useRef(null);
  const fourthRef = useRef(null);
  const refs = [firstRef, secondRef, thirtyRef, fourthRef];

  useEffect(() => {
    if(!pinCode[0] && refs[0].current) {
      refs[0].current.focus();
    }else if(!pinCode[1] && refs[1].current) {
      refs[1].current.focus();
    }else if(!pinCode[2] && refs[2].current) {
      refs[2].current.focus();
    }else if(!pinCode[3] && refs[3].current) {
      refs[3].current.focus();
    }
  })

  useEffect(() => {
    if (error && pinCode.length < 4) {
      clearPin();
    }
  }, [pinCode.length,error,clearPin]);

  const changePinCode = ({target: {value} },index) => {
    pinCode[index] = value;
    setPinCode([...pinCode]);
  };

  const keyPress = ({keyCode,target: { id }}) => {
    if (keyCode === 8) {
      if(+id === 3) {
        refs[2].current.focus();
      }
      else if(+id === 2) {
        refs[1].current.focus();
      }
      else if(+id === 1) {
        refs[0].current.focus();
      }
      console.log('delete',id)
    }
  }

  return (
    <Dialog open={open} disableBackdropClick onClose={() => setOpen(false)}>
      <div className={classes.dialog}>
        <DialogTitle>Введите пин-код</DialogTitle>
        <form onSubmit={e => onSubmit(e, pinCode.join(''))}>

          <div className={classes.pinCode_input_wrap}>
            {pinCode.map((item,idx) => (
              <input
                ref={refs[idx]}
                type="password"
                value={pinCode[idx]}
                key={idx}
                id={idx}
                maxLength={1}
                minLength={1}
                onKeyUp={keyPress}
                onChange={e => {
                  changePinCode(e,idx)
                }}
                className={classes.pinCode_input}
              />
            ))}
          </div>
          {error && message}
          <Button type='submit' disabled={(pinCode.join('').length < 4 && !error)} className={classes.button}
                  variant="contained" color="primary">
            Войти
          </Button>
        </form>
      </div>
    </Dialog>
  )
};

export default PinCodeForm;
