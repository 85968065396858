import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: '40px',

    '@media(max-width: 800px)': {
      padding: '20px'
    }
  },
  title_wrap: {
    marginTop: theme.spacing(3)
  },
  title_wrap__title: {
    color: theme.palette.blue.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(3),

    '@media(max-width: 500px)': {
      fontSize: 30
    }
  },
  form__title: {
    fontWeight: 700,
    color: theme.palette.grey.main
  },
  form__input_wrap: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'start',
    margin: '0 !important',

    '& form': {
      '@media(max-width: 500px)': {
        width: '100%',
        display: 'flex',
        flexFlow: 'column'
      }
    }
  },
  right_image_wrap: {
    background: theme.palette.blue.main,
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  right_image_wrap__image: {
    width: '550px',
    transform: 'translate(-80px)'
  },
  support: {
    position: 'absolute',
    bottom: '30px',
    right: '48px',
    textAlign: 'end'
  },
  support__text: {
    color: '#fff',
    fontWeight: 400,
    fontSize: '14px',
    margin: 0,
  }
}));

export default useStyles;
