import React from 'react';
import Typography from "@material-ui/core/Typography";
import {CardNotificationIcon, 
        CardNotificationIconSearch,
        CardNotificationIconChildrens,
        CardNotificationIconShield} from "../../../icons/icons";
import Map from "./Map";
// import moment from "moment";
const MainTop = ({map, total}) => {
    return (
        <div>
            <div className="MainPage-top__wrap">
                <div className="MainPage__cards">
                    <div className="MainPage__cards-item">
                        <Typography className="">{total && total.length ? total[0].title : null}</Typography>
                        <hr/>
                        <div className="cards__content">
                            <div className="cards__icon">
                                <CardNotificationIcon/>
                            </div>
                            <div className="cards__wrap">
                                <div className="cards__number">
                                    {total && total.length ? total[0].value : 0 }
                                </div>
                                <div className="cards__badge pink">
                                {total && total.length ? (total[0].percent >= 0 ? '+ ' + total[0].percent : total[0].percent) : 0 }%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MainPage__cards-item">
                        <Typography>{total && total.length ? total[1].title : null}</Typography>
                        <hr/>
                        <div className="cards__content">
                            <div className="cards__icon cards__icon_purple">
                                <CardNotificationIconSearch/>
                            </div>
                            <div className="cards__wrap">
                                <div className="cards__number">
                                    {total && total.length ? total[1].value : 0 }
                                </div>
                                <div className="cards__badge purple">
                                {total && total.length ? (total[1].percent >= 0 ? '+ ' + total[1].percent : total[1].percent) : 0 }%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MainPage__cards-item">
                        <Typography>{total && total.length ? total[2].title : null}</Typography>
                        <hr/>
                        <div className="cards__content">
                            <div className="cards__icon cards__icon_green">
                                <CardNotificationIconChildrens/>
                            </div>
                            <div className="cards__wrap">
                                <div className="cards__number">
                                    {total && total.length ? total[2].value : 0 }
                                </div>
                                <div className="cards__badge green">
                                {total && total.length ? (total[2].percent >= 0 ? '+ ' + total[2].percent : total[2].percent) : 0 }%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MainPage__cards-item">
                        <Typography>{total && total.length ? total[3].title : null}</Typography>
                        <hr/>
                        <div className="cards__content">
                            <div className="cards__icon cards__icon_blue">
                                <CardNotificationIconShield/>
                            </div>
                            <div className="cards__wrap">
                                <div className="cards__number">
                                    {total && total.length ? total[3].value : 0 }
                                </div>
                                <div className="cards__badge blue">
                                {total && total.length ? (total[3].percent >= 0 ? '+ ' + total[3].percent : total[3].percent) : 0 }%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="MainPage__map">
                    <div className="map__top">
                        <div className="map__name">
                            Случаи выявления в Кыргызстане
                        </div>
                    </div>
                    <Map map={map}/>
                    <div className="realtime">Данные в реальном времени</div>
                </div>
            </div>
        </div>
    );
};

export default MainTop;
