import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import useStyles from "../../Main-page/components/style";
import { useParams, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import axios from "../../../common/api/index";
import urls from "../../../common/urls";
import { useSelector } from "react-redux";

const TableMainTable = ({
  headers,
  body,
  setSchemaId,
  activeId,
  openSecondModal,
  loader,
  idies,
  dates,
  models,
  getSchema,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  let exist = id === "918bc9db-a779-415f-8b4e-47b978228b17";
  const [tempData, setTempData] = useState([]);
  const [results, setResults] = useState([]);
  useEffect(() => {
    if (exist) {
      axios
        .get(`https://baldarbazasy.mlsp.gov.kg/${urls.GET_HISTORY_RECORDS}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "SmartId-SessionId"
            )}`,
          },
        })
        .then((res) => {
          setTempData(res.data.result);
        });
    }
  }, [exist]);
  const history = useHistory();
  const [indexes, setIdx] = React.useState(null);
  const handleClick = (index) => {
    // getSchema(models[index], idies[index]);
    if (tempData.find) {
      let xd = tempData.find((el) => el.id === idies[index]);
      console.log(xd);
      setResults(tempData.find((el) => el.id === idies[index]).results);
      setIdx(index);
      if (index === indexes) {
        setIdx(null);
      }
    }
  };
  // const colors = [
  //     {
  //         bg: "rgba(58, 192, 159, 0.2)",
  //         color: "#3AC09F"
  //     },
  //     {
  //         bg: "rgba(100, 98, 202, 0.2)",
  //         color: "#6462CA"
  //     }
  // ];
  const createNewInfoItem = (index, date) => () => {
    if (date) {
      localStorage.setItem("time", date);
    } else {
      localStorage.setItem("time", "");
    }

    history.push(`/create/questionnaire/${models[index].id}/temp`);
    // history.push(`/create/questionnaire/${models[index].id}/${models[index].model.childId || models[index].model.ipzrId}`);
    window.location.reload();
  };
  // const getInfoItem = (date, index) => () => {
  //     // openSecondModal(item);
  //     localStorage.setItem('data', date);
  //     localStorage.setItem('data', date);
  //     console.log('models[index]', models[index].id);
  //     history.push(`/create/questionnaire/${models[index].id}`);
  //     // window.location.reload()
  // }

  const checkSignal = (rowId) => {
    if (String(id) === "274f0c5d-2c02-43b2-8d59-77e73b4494ea") {
      history.push(`/create/signal/${rowId}`);
      window.location.reload(true);
    }
  };

  const matches = useMediaQuery("(max-width: 900px)");

  return (
    <>
      {!exist ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ display: matches ? "none" : null }}>
              <TableRow>
                {headers.map((item, idx) => {
                  return (
                    <TableCell className={classes.tableCell} key={item + idx}>
                      {item}
                    </TableCell>
                  );
                })}
                <TableCell className='table__absolute-buttons table__absolute-header-cell'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {body.map((model, idx) => {
                return (
                  <TableRow hover key={model + idx} className='table__row'>
                    {matches && (
                      <>
                        <TableCell className={classes.tableBodyCell}>
                          <span style={{ display: matches ? "initial" : null }}>
                            {headers[1]}
                          </span>
                          {model[1]}
                        </TableCell>
                        <TableCell className={classes.tableBodyCell}>
                          <span style={{ display: matches ? "initial" : null }}>
                            {headers[2]}
                          </span>
                          {model[2]}
                        </TableCell>
                      </>
                    )}

                    {!matches &&
                      model.map((item, idx) => {
                        return (
                          <TableCell
                            className={classes.tableBodyCell}
                            key={item + idx}
                          >
                            {item !== "й" ? item : "---"}
                          </TableCell>
                        );
                      })}
                    <TableCell
                      align='right'
                      className='table__absolute-buttons table__absolute-buttons_padding_global'
                    >
                      <button
                        className='anketa-button'
                        onClick={() => {
                          if (
                            Object.keys(models[idx].model).some(
                              (el) =>
                                models[idx].model[el] === "Утвержденный_ИПЗР" ||
                                models[idx].model[el] === "Завершенный_ИПЗР"
                            )
                          ) {
                            return window.open(
                              `https://baldarbazasy.mlsp.gov.kg/api/db/GetIpzrPlan/${
                                models[idx].id
                              }/${localStorage.getItem("SmartId-SessionId")}`,
                              matches ? "_self" : "_blank"
                            );
                          } else {
                            checkSignal(models[idx].id);
                            setSchemaId(models[idx].id);
                            getSchema(models[idx], idies[idx]);
                          }
                        }}
                      >
                        Открыть
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer>
          <Table
            className='collapsible-table'
            aria-label='collapsible-table table'
          >
            <TableHead style={{ display: matches ? "none" : null }}>
              <TableRow>
                <TableCell></TableCell>
                {headers.map((item, idx) => (
                  <TableCell className={classes.tableCell} key={item + idx}>
                    {item}
                  </TableCell>
                ))}
                <TableCell className='table__absolute-buttons table__absolute-header-cell'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {body.map((model, idx) => {
                return (
                  <React.Fragment>
                    <TableRow
                      onClick={() => handleClick(idx)}
                      className='table__row'
                    >
                      <TableCell style={{ display: matches ? "none" : null }}>
                        <IconButton aria-label='expand row' size='small'>
                          {indexes === idx ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>

                      {matches && <TableCell>{model[1]}</TableCell>}

                      {!matches &&
                        model.map((item, idx) => {
                          return (
                            <TableCell key={item + idx}>
                              {item !== "й" ? item : "---"}
                            </TableCell>
                          );
                        })}
                      <TableCell
                        align='right'
                        className='table__absolute-buttons'
                      >
                        <button
                          className='anketa-button'
                          style={{ fontSize: 15 }}
                        >
                          Показать анкеты
                        </button>
                      </TableCell>
                    </TableRow>
                    <TableRow
                      className='child-status'
                      style={{ width: "100%" }}
                    >
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={12}
                      >
                        <Collapse
                          in={indexes === idx}
                          timeout='auto'
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table aria-label='purchases'>
                              <TableBody>
                                <div className='identifying-status'>
                                  <div className='create-anketa-button__wrapper'>
                                    <button
                                      className='create-anketa-button'
                                      onClick={createNewInfoItem(idx)}
                                    >
                                      Создать анкету
                                    </button>
                                  </div>
                                  <p className='anketa-history'>
                                    История Анкет:
                                  </p>

                                  <div>
                                    {results.length &&
                                      results.map((item, i) => {
                                        return (
                                          <div className='identifying-status__item'>
                                            <span style={{ color: "#0E4FFA" }}>
                                              Анкета №{i + 1}
                                            </span>
                                            &nbsp; &nbsp;
                                            <span>{item}</span>
                                            <div
                                              style={{
                                                background:
                                                  "rgba(100, 98, 202, 0.2)",
                                                color: "#6462CA",
                                                cursor: "pointer",
                                              }}
                                              onClick={createNewInfoItem(
                                                idx,
                                                item
                                              )}
                                            >
                                              Открыть
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TableMainTable;
