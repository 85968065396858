import React, { useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CustomCheckbox from "../components/checkbox";
import { useSelector } from "react-redux";
import "./jsx-parse.scss";
import ruLocale from "date-fns/locale/ru";
import * as moment from "moment";
// import {useParams} from "react-router-dom";

const useStyles = makeStyles({
  textField: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: "5px",
    margin: "10px",
    // height: '48px',
    overflow: "hidden",
    width: "31%",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "10px 0",
    },

    "& input": {
      padding: "23px 12px 5px",
      fontSize: "15px",
      fontFamily: "Open Sans",
      color: "#333",
    },
    "& div": {
      borderRadius: "4px",
      background: "inherit",
    },
    "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
      transform: "translate(12px, 5px) scale(0.75)",
      fontSize: "12px",
      top: "1px",
      color: "#333",
    },
    "& label": {
      transform: "translate(12px, 15px) scale(1)",
      fontFamily: "Open Sans",
      whiteSpace: "nowrap",
      color: "#A2A1A6",
      fontSize: "15px",
    },
  },

  date: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: "5px",
    fontFamily: "Open Sans",
    margin: "10px",
    height: "48px",
    width: "31%",
    overflow: "hidden",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "10px 0",
    },

    "& .MuiInputBase-input": {
      padding: "7px 0 5px 13px",
      fontSize: "15px",
      color: "#333",
    },
    "& label": {
      padding: "0 0 0 16px",
      top: "-9px",
      whiteSpace: "nowrap",
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: "15px",
    },
    "& .MuiInputLabel-shrink": {
      top: "5px",
      fontSize: "12px",
      color: "#333",
    },
  },

  textarea: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: "5px",
    fontFamily: "Roboto",
    margin: "10px",
    width: "31%",
    color: "#333",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "10px 0",
    },

    "& .MuiInputBase-multiline": {
      padding: "6px 5px 7px 10px",
      fontFamily: "Open Sans",
    },
    "& .MuiInputBase-inputMultiline": {
      fontSize: "15px",
      color: "#333",
      padding: "5px 0 5px 3px",
    },
    "&::placeholder": {
      color: "black",
    },
  },

  radioGroup: {
    fontFamily: "Roboto",
    width: "45%",
    margin: "15px",
    color: "#333",
    fontSize: "15px",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "10px 0",
    },

    "& .MuiSvgIcon-root": {
      width: "20px",
      height: "20px",
    },
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: "#0E4FFA",
    },
    "& .MuiRadio-root": {
      padding: "0 16px 0 12px",
    },
    "& .MuiFormGroup-root": {
      marginTop: "16px ",
    },
    "& .MuiFormControlLabel-root": {
      marginBottom: "6px",
    },
    "& .MuiFormLabel-root": {
      lineHeight: "140% !important",
      fontFamily: "Open Sans",
      color: "black",
      fontSize: "15px !important",
    },
  },

  select: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: "5px",
    margin: "10px",
    width: "31%",
    height: "48px",
    overflow: "hidden",

    "@media (max-width: 900px)": {
      width: "100%",
      margin: "10px 0",
    },

    "& div": {
      borderRadius: "5px",
    },
    "& label": {
      whiteSpace: "nowrap",
      padding: "0 0 0 16px",
      zIndex: "1",
      top: "-9px",
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: "15px",
    },
    "& .MuiInputLabel-shrink": {
      top: "5px",
      color: "#333",
      fontSize: "12px",
    },
    "& .MuiFilledInput-input": {
      padding: "22px 12px 5px",
      fontSize: "15px",
      fontFamily: "Open Sans",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "inherit",
    },
  },

  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const JsxParse = ({
  item,
  initialValue,
  checkedAll,
  allChecked,
  eventHandler,
  objData,
  setObjData,
  idx,
  serverData,
  regions,
  setRegion,
  district,
  setDistrict,
  districts,
  setDistricts,
  countryside,
  setCountryside,
  countrysides,
  setCountrysides,
  village,
  setVillage,
  villages,
  setVillages,
}) => {
  const [value, setValue] = useState(null);
  const { type } = item;
  let propsObject;
  const classes = useStyles();
  const selector = useSelector(
    (store) => store.mainPageReducer.schema.nativeLabels
  );
  if (typeof initialValue === "object") {
    propsObject = Object.keys(initialValue);
  } else {
    propsObject = initialValue;
  }
  useEffect(() => {
    // if (typeof initialValue === 'object') {
    //     propsObject = Object.keys(initialValue);
    // } else {
    //     propsObject = initialValue;
    // }
    // if (serverData && serverData.length) {
    //   let keysToFind = serverData[0].model
    //   let index = Object.keys(keysToFind).findIndex(item => {
    //     return item === initialValue;
    //   })
    //   if(index) {
    //     setValue(serverData[0].model[initialValue])
    //   }
    // }
    if (serverData) {
      let keysToFind = serverData;
      let index = Object.keys(keysToFind).findIndex((item) => {
        return String(item) === String(initialValue);
      });
      if (index > -1) {
        setValue(keysToFind[initialValue]);
      }
    }
  }, [initialValue, propsObject, serverData]);

  useEffect(() => {
    if (selector) {
      setObjData && setObjData(selector);
    }
  }, [selector, setObjData]);

  let toTimestamp = (strDate) => Date.parse(strDate);
  const changeValue = (event, valueFromApi) => {
    const {
      target: { value },
    } = event;
    if (typeof initialValue === "string") {
      if (item.inputType === "date") {
        const temp = toTimestamp(value);
        setValue(temp);
        eventHandler(
          {
            [initialValue]: temp,
          },
          idx
        );
        return;
      } else if (item.inputType === "radios") {
        setValue(valueFromApi);
        eventHandler(
          {
            [propsObject[0]]: valueFromApi,
          },
          idx
        );
      }
      setValue(value);
      eventHandler({
        [initialValue]: value,
      });
    } else {
      if (item.inputType === "date") {
        const temp = toTimestamp(value);
        setValue(temp);
        eventHandler(
          {
            [propsObject[0]]: temp,
          },
          idx
        );
        return;
      }

      setValue(value);
      eventHandler(
        {
          [propsObject[0]]: value,
        },
        idx
      );
    }
  };
  const checkboxes = (item, init) => {
    if (
      Object.keys(objData).some(
        (el) => el === (typeof init === "object" ? Object.keys(init)[0] : init)
      )
    ) {
      delete objData[typeof init === "object" ? Object.keys(init)[0] : init];
      return setObjData({ ...objData });
    } else {
      if (typeof init === "object") {
        return setObjData({ ...objData, [Object.keys(init)[0]]: "да" });
      } else {
        Object.assign(objData, { [init]: "да" });
        return setObjData({ ...objData });
      }
    }
  };

  if (
    (item.model === "select-aeozhPQSFy4YYLNlXUbJr" ||
      item.model === "select-5F5fhfqGKEDn_Vt162zZw") &&
    value
  ) {
    const region = regions.find((item) => item.region === value);

    if (region && region.districts) {
      setDistricts(region.districts);
    } else if (region && region.villages) {
      setVillages(region.villages);
    }
  }

  if (
    (item.model === "select-URRVEdr_Hjzbdu1O_DtS9" ||
      item.model === "select-QjgEVYmUPWzeQoCLJ8udk") &&
    value
  ) {
    const district = districts.find((item) => item.district === value);

    if (district && district.countrysides) {
      setCountrysides(district.countrysides);
    }
  }

  if (
    (item.model === "select-kylqALnvX-F-2ZYXe_-NZ" ||
      item.model === "select-02Xq4Uwi809aJ3pwhJmq1") &&
    value
  ) {
    const countryside = countrysides.find((item) => item.countryside === value);

    if (countryside && countryside.villages) {
      setVillages(countryside.villages);
    }
  }

  if (type === "input") {
    switch (item.inputType) {
      case "date":
        console.log("debug", value);
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <DatePicker
              variant='filled'
              className={`${classes.date} ${item.model}`}
              InputProps={{ classes }}
              disableUnderline={true}
              autoOk
              fullWidth
              format='dd.MM.yyyy'
              margin='normal'
              name={item.model}
              label={item.label}
              // value={value ? moment(new Date(JSON.parse(+value))).format('YYYY-MM-DD') : null}
              value={value ? moment(value).format("YYYY-MM-DD") : null}
              onChange={(date) => changeValue({ target: { value: date } })}
            />
          </MuiPickersUtilsProvider>
          // <label htmlFor={idx} key={idx} style={{display: 'block'}}>
          //   {item.label}
          //   <input onChange={changeValue} value={value ? moment(new Date(JSON.parse(+value))).format('YYYY-MM-DD') : ''} style={{display: 'block'}}
          //          required={item.required} id={idx} type={item.inputType} min={item.min} max={item.max}/>
          // </label>
        );

      case "number":
      case "text":
        if (item.model === "number-X2WqS0X3QWpMx1ouZCPyU") {
          return (
            <TextField
              className={`${classes.textField} ${item.model}`}
              value={value ? value : initialValue[item.model]}
              name={item.model}
              type={item.inputType}
              autoComplete={item.autocomplete}
              required={item.required}
              onChange={changeValue}
              label={item.label || item.label_ru}
              variant='filled'
              helperText={
                value && +value.length !== 0 && value.length > 0
                  ? value.length >= 6
                    ? null
                    : "Это поле обязательно"
                  : null
              }
              // error={value && value.length != 0 && value.length >= 6 ? false : true }
              error={
                value && +value.length !== 0 && value.length > 0
                  ? value.length >= 6
                    ? false
                    : true
                  : false
              }
              placeholder='+996-(706)-10-10-10'
              InputProps={{ classes }}
              style={{ overflow: "hidden" }}
            />
          );
        }
        return (
          <TextField
            className={`${classes.textField} ${item.model}`}
            value={value ? value : initialValue[item.model]}
            name={item.model}
            type={item.inputType}
            autoComplete={item.autocomplete}
            required={item.required}
            onChange={(event) => {
              changeValue(event);
            }}
            label={item.label || item.label_ru}
            variant='filled'
            InputProps={{ classes }}
            inputProps={{ maxLength: item.maxlength ? item.maxlength : 255 }}
          />

          // <label key={idx} style={{display: 'block'}}>
          //   {item.label}
          //   <input onChange={changeValue}
          //          id={idx}
          //          value={value}
          //          placeholder={item.placeholder}
          //          required={item.required}
          //          autoComplete={item.autocomplete} minLength={item.minLength}
          //          maxLength={item.maxLength}
          //          type={item.inputType} style={{display: 'block'}}
          //   />
          // </label>
        );

      default:
        return <p key={idx}>inputType {item.inputType}</p>;
    }
  } else if (type === "radios") {
    if (item.model === "radio-dbb2OO3_DGTpm412g55Vd")
      return (
        <FormControl
          component='fieldset'
          className={`${classes.radioGroup} ${item.model}`}
        >
          <FormLabel component='legend'>
            <span>Ребенок найден?</span>
          </FormLabel>
          <RadioGroup name={item.label} value={value} onChange={changeValue}>
            {item.values.map((option, index) => (
              <FormControlLabel
                label={option}
                value={option}
                key={index}
                control={
                  <Radio
                    checked={String(value) === String(option) ? true : false}
                    color='primary'
                  />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      );

    return (
      <FormControl
        component='fieldset'
        className={`${classes.radioGroup} ${item.model}`}
      >
        <FormLabel component='legend'>
          <span>{item.label}</span>
        </FormLabel>
        <RadioGroup name={item.label} value={value} onChange={changeValue}>
          {item.values.map((option, index) => (
            <FormControlLabel
              label={option}
              value={option}
              key={index}
              control={
                <Radio
                  checked={String(value) === String(option) ? true : false}
                  color='primary'
                />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  } else if (type === "select") {
    // if (item.model === 'select-URRVEdr_Hjzbdu1O_DtS9') {
    //     return (

    //     )
    // }

    // if (
    //     item.model === 'select-aeozhPQSFy4YYLNlXUbJr' ||
    //     item.model === 'select-URRVEdr_Hjzbdu1O_DtS9' ||
    //     item.model === 'select-kylqALnvX-F-2ZYXe_-NZ' ||
    //     item.model === 'select-NYpNvsrNLkdbkSIC66bRI'
    // ) return null

    if (
      item.model === "select-aeozhPQSFy4YYLNlXUbJr" ||
      item.model === "select-5F5fhfqGKEDn_Vt162zZw"
    ) {
      return (
        <FormControl className={`${classes.select} ${item.model}`}>
          <InputLabel id={item.label + idx}>{item.label}</InputLabel>
          <Select
            labelId={item.label + idx}
            id={item.label + idx}
            name={item.label + idx}
            value={value}
            key={value}
            defaultValue={value}
            displayEmpty={false}
            // disableUnderline={true}
            onChange={(e) => {
              changeValue(e);
              setRegion(e.target.value);

              const region = regions.find(
                (region) => region.region === e.target.value
              );

              if (region && region.districts) {
                setDistricts(region.districts);
                setCountrysides([]);
                setVillages([]);
              } else {
                setDistricts([]);
                setCountrysides([]);
                setVillages([]);
              }
            }}
            variant='filled'
            required
            // id='select'
          >
            {/* <MenuItem disabled>Выберите значение</MenuItem> */}
            {regions.map((option, index) => (
              <MenuItem
                // selected={String(value) === String(option) ? true : false}
                value={option.region}
                key={option.region}
              >
                {option.region}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (
      item.model === "select-URRVEdr_Hjzbdu1O_DtS9" ||
      item.model === "select-QjgEVYmUPWzeQoCLJ8udk"
    ) {
      return (
        <FormControl className={`${classes.select} ${item.model}`}>
          <InputLabel id={item.label + idx}>{item.label}</InputLabel>
          <Select
            labelId={item.label + idx}
            id={item.label + idx}
            name={item.label + idx}
            value={value}
            key={value}
            defaultValue={value}
            displayEmpty={false}
            // disableUnderline={true}
            onChange={(e) => {
              changeValue(e);
              setDistrict(e.target.value);

              const district = districts.find(
                (district) => district.district === e.target.value
              );

              if (district && district.countrysides) {
                setCountrysides(district.countrysides);
                setVillages([]);
              } else {
                setCountrysides([]);
                setVillages([]);
              }
            }}
            variant='filled'
            // required
            // id='select'
          >
            {/* <MenuItem disabled>Выберите значение</MenuItem> */}
            {districts.map((option, index) => (
              <MenuItem
                // selected={String(value) === String(option) ? true : false}
                value={option.district}
                key={option.district}
              >
                {option.district}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (
      item.model === "select-kylqALnvX-F-2ZYXe_-NZ" ||
      item.model === "select-02Xq4Uwi809aJ3pwhJmq1"
    ) {
      return (
        <FormControl className={`${classes.select} ${item.model}`}>
          <InputLabel id={item.label + idx}>{item.label}</InputLabel>
          <Select
            labelId={item.label + idx}
            id={item.label + idx}
            name={item.label + idx}
            value={value}
            key={value}
            defaultValue={value}
            displayEmpty={false}
            // disableUnderline={true}
            onChange={(e) => {
              changeValue(e);
              setCountryside(e.target.value);

              const countryside = countrysides.find(
                (item) => item.countryside === e.target.value
              );

              if (countryside && countryside.villages) {
                setVillages(countryside.villages);
              } else {
                setVillages([]);
              }
            }}
            variant='filled'
            // required
            // id='select'
          >
            {/* <MenuItem disabled>Выберите значение</MenuItem> */}
            {countrysides.map((option, index) => (
              <MenuItem
                // selected={String(value) === String(option) ? true : false}
                value={option.countryside}
                key={option.countryside}
              >
                {option.countryside}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    if (
      item.model === "select-NYpNvsrNLkdbkSIC66bRI" ||
      item.model === "select-7VLDxTxOJJHC65KGvcFHB"
    ) {
      return (
        <FormControl className={`${classes.select} ${item.model}`}>
          <InputLabel id={item.label + idx}>{item.label}</InputLabel>
          <Select
            labelId={item.label + idx}
            id={item.label + idx}
            name={item.label + idx}
            value={value}
            key={value}
            defaultValue={value}
            displayEmpty={false}
            // disableUnderline={true}
            onChange={(e) => {
              changeValue(e);
              setVillage(e.target.value);
            }}
            variant='filled'
            // required
            // id='select'
          >
            {/* <MenuItem disabled>Выберите значение</MenuItem> */}
            {villages.map((option, index) => (
              <MenuItem
                // selected={String(value) === String(option) ? true : false}
                value={option.village}
                key={option.village}
              >
                {option.village}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      // <label key={idx} htmlFor={idx} style={{display: 'block'}}>
      //   {item.label}
      //   <select onChange={changeValue} value={value} id={idx} required={item.required} style={{display: 'block'}}>
      //     {
      //       (item.selectOptions && item.selectOptions.noneSelectedText) &&
      //       <option>{item.selectOptions.noneSelectedText}</option>
      //     }
      //     {item.values.map((option, index) => <option key={option + index}>{option}</option>)}
      //   </select>
      // </label>

      <FormControl className={`${classes.select} ${item.model}`}>
        <InputLabel id={item.label + idx}>{item.label}</InputLabel>
        <Select
          labelId={item.label + idx}
          id={item.label + idx}
          name={item.label + idx}
          value={value}
          key={value}
          defaultValue={value}
          displayEmpty={false}
          // disableUnderline={true}
          onChange={changeValue}
          variant='filled'
          required={item.model === "select-URRVEdr_Hjzbdu1O_DtS9"}
          // id='select'
        >
          {/* <MenuItem disabled>Выберите значение</MenuItem> */}
          {item.values.map((option, index) => (
            <MenuItem
              // selected={String(value) === String(option) ? true : false}
              value={option}
              key={option + index}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (type === "textArea") {
    return (
      // <label style={{margin: '10px 0', background: '#F0F4FD', borderBottom: '#00ABE9',display: 'block'}} key={idx} htmlFor={idx}>
      //   {item.label}
      //   <textarea onChange={changeValue} id={idx} value={value} required={item.required} style={{display: 'block'}}/>
      // </label>
      <FormControl className={`${classes.textarea} ${item.model}`}>
        <TextField
          multiline
          name={item.model}
          disableUnderline={true}
          InputProps={{ classes }}
          placeholder={item.label}
          id={idx}
          value={value ? value : initialValue[item.model]}
          required={item.required}
          onChange={changeValue}
        />
      </FormControl>
    );
  } else if (type === "checkbox") {
    return (
      <>
        <RadioGroup>
          <FormControlLabel
            value={value ? value : initialValue[item.model]}
            onClick={() => checkboxes(item.model, initialValue)}
            control={
              <CustomCheckbox
                check={value ? value : initialValue[item.model]}
              />
            }
            label={item.label}
          />
        </RadioGroup>
      </>
    );
  } else {
    return <p key={idx}>{type}</p>;
  }
};

export default JsxParse;
