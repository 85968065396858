import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {Grid,Typography, useMediaQuery } from '@material-ui/core';
import {
  changeInn,
  getInnActionThunk,
  innCheckThunk,
  getEmployeeName,
  clearEmployeeName,
  checkEmployeeInn,
  checkPinCode,
  pinCodeClear
} from './accountsActions';
import OrganizationLoginForm from './components/organizationLoginForm';
import EmployeeLoginForm from './components/employeeLoginForm';
import PinCodeForm from './components/pinCodeForm';
import Emblem from '../../common/components/Emblem';
import useStyles from "./styles";
import children from './assets/children.png';
import unicef from './assets/unicef.png';

const AuthInn = () => {
  const matches = useMediaQuery('(max-width: 800px)')

  const classes = useStyles();
  const dispatch = useDispatch();
  const selector = useSelector(store => store.authReducer);


  const organizationLoginFormSubmit = (e, inn) => {
    e.preventDefault();
    dispatch(innCheckThunk(inn))
  }

  const employeeLoginForm = (e, inn) => {
    e.preventDefault();
    dispatch(checkEmployeeInn(inn, selector.innOrganization));
  }

  const pinCodeFormSubmit = (e, pin) => {
    e.preventDefault();
    dispatch(checkPinCode(selector.employeeInn, selector.innOrganization, pin))
  }

  const showInput = () => {
    if (selector.userData.approve) {
      return <Redirect to={'/'}/>
    }
    else if (selector.userData.inn) {
      return (
        <EmployeeLoginForm
          clearInn={() => dispatch(clearEmployeeName())}
          employeeName={selector.employeeName}
          getInnThunk={inn => dispatch(getEmployeeName(inn))}
          error={selector.error}
          onSubmit={employeeLoginForm}/>
      )
    }

    return (
      <OrganizationLoginForm
        name={selector.name}
        getInnThunk={inn => dispatch(getInnActionThunk(inn))}
        clearInn={() => dispatch(changeInn())}
        error={selector.error}
        onSubmit={organizationLoginFormSubmit}
      />
    )
  }


  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={matches ? 12 : 7}>

          <Emblem />

          <div className={classes.title_wrap}>
            <Typography variant="h3" className={classes.title_wrap__title} component="p">
              Дети в трудной жизненной ситуации
            </Typography>
          </div>

          <div className={classes.form}>
            <Typography className={classes.form__title} variant='h6' component='p'>
              Вход в систему
            </Typography>
            <div className={classes.form__input_wrap}>
              {showInput()}

              {(selector.userData.innEmployee && selector.userData.innOrganisation) && (
                <PinCodeForm
                  message={selector.pin_message}
                  error={selector.error}
                  clearPin={() => dispatch(pinCodeClear())}
                  onSubmit={pinCodeFormSubmit}/>
              )}

            </div>
          </div>
        </Grid>

        {
          matches ? null :
          <Grid item xs={5} className={classes.right_image_wrap}>
            <img src={children} className={classes.right_image_wrap__image} alt="Дети"/>
            <div className={classes.support}>
              <img src={unicef} alt="Логотип Unicef"/>
              <p className={classes.support__text}>Создано при поддержке UNICEF</p>
            </div>
          </Grid>
        }
      </Grid>
    </div>
  )

  // return (
  //   <OrganizationLoginForm
  //     name={selector.name}
  //     getInnThunk={inn => dispatch(getInnActionThunk(inn))}
  //     clearInn={() => dispatch(changeInn())}
  //     error={selector.error}
  //     onSubmit={organizationLoginFormSubmit}/>
  // )
}


export default AuthInn;
