import { CompassCalibrationOutlined } from "@material-ui/icons";
import moment from "moment";

const parseJson = (models,labels) => {
  const headers = labels;
  const val = models;
  const array = [];
  const parsedHeaders = [];

  const colNames = Object.keys(headers).map((headerKey) => {
    parsedHeaders.push(headers[headerKey]);
    return headerKey;
  });

  const colsData = val.map(item => {
    return item.model
  });

  for (let i = 0; i < val.length; i++) {
    array.push([]);
    colNames.map((name,j) => {
      array[i][j] ='---';
      Object.keys(colsData[i]).find(it => {
        if(name === it) {
          if (colsData[i][it] === '' || colsData[i][it] === null) {
            array[i][j] = '---'
          } else {
            switch (typeof colsData[i][it]) {
              case 'string':
                if (it.indexOf('map') !== -1) {
                  array[i][j] = 'Полигон на карте'
                } else {
                  array[i][j] = colsData[i][it]
                }
                if (it.indexOf('checkbox') !== -1) {
                  let text = ''
                  colsData[i][it] ? (text = 'да') : (text = 'нет')
                  array[i][j] = text
                }
                if (it.indexOf('date') !== -1) {
                  try {
                    let data = JSON.parse(colsData[i][it])

                    array[i][j] = moment(JSON.parse(data)).format(
                      'DD.MM.YYYY'
                    )
                  } catch(err) {
                    console.log('data format error')
                    array[i][j] = colsData[i][it]
                  }
                } else {
                  array[i][j] = colsData[i][it]
                }
                break
              case 'boolean':
                let text = ''
                colsData[i][it] ? (text = 'да') : (text = 'нет')
                array[i][j] = text
                break
              case 'number':
                if (it.indexOf('date') !== -1) {
                  array[i][j] = moment(colsData[i][it]).format('DD.MM.YYYY')
                } else {
                  array[i][j] = colsData[i][it]
                }
                break
              default:
                array[i][j] = colsData[i][it]
                break
            }
          }
        }
        return null
      })
      return null
    })
  }

  return {
    models: array,
    labels: parsedHeaders
  }

};

export default parseJson;
