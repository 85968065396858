import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: '0 30px 30px',
  },
  input: {
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    width: '100%'
  },
  button: {
    width: '100%',
    maxWidth: '370px',
    background: theme.palette.lightBlue.main,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '18px',
    marginTop: theme.spacing(2),
    '&:hover': {
      background: theme.palette.blue.main
    }
  },
  label: {
    fontWeight: 400,
    color: theme.palette.dark.main
  },
  pinCode_input: {
    width: '50px',
    height: '50px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    border: 'none',
    textAlign: 'center',
    fontSize: '50px'
  },
  pinCode_input_wrap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default useStyles;
