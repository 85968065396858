import React from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import moment from 'moment'
import axios from '../common/api'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    },
  },
  textField: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    margin: '10px',
    // height: '48px',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& input': {
      // padding: '23px 12px 5px',
      fontSize: '15px',
      fontFamily: "Open Sans",
      color: "#333",
    },
    '& div': {
      borderRadius: '4px',
      background: 'inherit'
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 5px) scale(0.75)',
      fontSize: '12px',
      top: '1px',
      color: '#333',
    },
    '& .MuiInputBase-input': {
      padding: '10px 12px'
    },
    '& label': {
      transform: 'translate(12px, 15px) scale(1)',
      fontFamily: "Open Sans",
      whiteSpace: 'nowrap',
      color: "#A2A1A6",
      fontSize: '15px',
    },
  },
  select: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    margin: '10px',
    width: '31%',
    height: '48px',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& div': {
      borderRadius: '5px',
    },
    '& label': {
      whiteSpace: 'nowrap',
      padding: '0 0 0 16px',
      zIndex: '1',
      top: '-9px',
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: '15px',
    },
    '& .MuiInputLabel-shrink': {
      top: '5px',
      color: '#333',
      fontSize: '12px'
    },
    '& .MuiFilledInput-input': {
      padding: '22px 12px 5px',
      fontSize: '15px',
      fontFamily: "Open Sans",

    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'inherit',
    }
  },

  date: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    fontFamily: "Open Sans",
    margin: '10px',
    height: '48px',
    width: '31%',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& .MuiInputBase-input': {
      padding: '7px 0 5px 13px',
      fontSize: '15px',
      color: '#333'
    },
    '& label': {
      padding: '0 0 0 16px',
      top: '-9px',
      whiteSpace: 'nowrap',
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: '15px',
    },
    '& .MuiInputLabel-shrink': {
      top: '5px',
      fontSize: '12px',
      color: '#333'
    },
  },

  textarea: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    fontFamily: 'Roboto',
    margin: '10px',
    width: '31%',
    color: '#333',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& .MuiFormLabel-root': {
      // margin: 10
    },

    '& .MuiInputBase-multiline': {
      padding: '6px 5px 7px 10px',
      fontFamily: "Open Sans",
    },
    '& .MuiInputBase-inputMultiline': {
      fontSize: '15px',
      color: '#333',
      padding: '5px 0 5px 3px'
    },
    '&::placeholder': {
      color: 'black',
    }
  },


  tableCell: {
    fontSize: '13px',
    fontWeight: 600,
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset'
    }
  },
  tableBodyCell: {
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset',
      display: 'flex',
      flexFlow: 'column',

      '& span': {
        color: 'gray'
      }
    }
  },

  dialogWidth: {
    width: '100%',

    '& .MuiFormControl-root': {
      width: '100%',
      margin: 0
    }
  }
})

const headers = ['№', 'ФИО ребенка', 'Назначено', 'Испольнить до', 'Статус']

export default function TasksPage() {
  const classes = useStyles()
  const [statusList, setStatusList] = React.useState([])
  const [tasks, setTasks] = React.useState([])
  const [status, setStatus] = React.useState(null)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [showTaskModal, setShowTaskModal] = React.useState(false)
  const [selectedTask, setSelectedTask] = React.useState(null)
  const [comment, setComment] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const matches = useMediaQuery('(max-width: 900px)')

  React.useEffect(() => {
    axios
      .get('/api/Issue/getStatusList')
      .then(res => setStatusList(res.data.result))
  }, [])

  const showTask = task => () => {
    setSelectedTask(task)
    setShowTaskModal(true)
  }

  const closeModal = () => {
    setSelectedTask(null)
    setShowTaskModal(false)
    setComment('')
  }

  const getTasks = () => {
    setLoading(true)

    axios
      .get('/api/Issue/getChildList', { params: { status, startDate, endDate } })
      .then(res => setTasks(res.data.result))
      .finally(() => setLoading(false))
  }

  const updateTask = () => {
    closeModal()
    setLoading(true)

    axios
      .put(`/api/Issue/edit/${selectedTask.id}`, { comment })
      .then(getTasks)
      .finally(() => setLoading(false))
  }

  return (
    <div className="TasksPage">
      <span
        style={{
          fontWeight: 600,
          fontSize: 28
        }}
      >
        Задачи
      </span>
      <div className="TasksPage__heading">
        <FormControl className={`${classes.select}`}>
          <InputLabel>Статус</InputLabel>
          <Select
            name="status"
            value={status}
            onChange={event => setStatus(event.target.value)}
            variant='filled'
          >
            {statusList.map((option, index) => <MenuItem
              value={option.status} key={option + index}>{option.title}</MenuItem>)}
          </Select>
        </FormControl>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DatePicker
            variant="filled"
            className={`${classes.date}`}
            autoOk
            InputProps={{ disableUnderline: true }}
            fullWidth
            label="Период с:"
            format="dd.MM.yyyy"
            margin="normal"
            name="startDate"
            value={startDate ? moment(startDate).format("YYYY-MM-DD") : null}
            onChange={date => setStartDate(date)}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DatePicker
            variant="filled"
            className={`${classes.date}`}
            InputProps={{ disableUnderline: true }}
            autoOk
            fullWidth
            label="Период по:"
            format="dd.MM.yyyy"
            margin="normal"
            name="endDate"
            value={endDate ? moment(endDate).format("YYYY-MM-DD") : null}
            onChange={date => setEndDate(date)}
          />
        </MuiPickersUtilsProvider>

        <Button color="primary" variant="contained" className="Button" disableElevation onClick={getTasks}>Показать</Button>
      </div>

      {
        loading &&
        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
      }

      <div className="TasksPage__table table__content" style={{ paddingTop: 0 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead style={{ display: matches ? 'none' : null }} >
              <TableRow>
                {headers.map((item, idx) => {
                  return (
                    <TableCell
                      className={classes.tableCell}
                      key={item + idx}
                    >
                      {item}
                    </TableCell>
                  )
                })}
                <TableCell className="table__absolute-buttons table__absolute-header-cell"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading && tasks.map((task, index) => {
                return <TableRow
                  hover
                  key={task.id}
                  className="table__row"
                >
                  {
                    matches && <>
                      <TableCell
                        className={classes.tableBodyCell}
                      >
                        <span style={{ display: matches ? 'initial' : null }}>{headers[1]}</span>
                        {task.childFio}
                      </TableCell>
                      <TableCell
                        className={classes.tableBodyCell}
                      >
                        <span style={{ display: matches ? 'initial' : null }}>{headers[2]}</span>
                        {task.appointmentDate}
                      </TableCell>

                      <TableCell
                        className={classes.tableBodyCell}
                      >
                        <button className="anketa-button" onClick={showTask(task)}>Открыть</button>
                      </TableCell>
                    </>
                  }

                  {!matches &&
                      <>
                        <TableCell className={classes.tableBodyCell}>
                          {index}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {task.childFio}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {task.appointmentDate}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {task.dueDate}
                        </TableCell>

                        <TableCell className={classes.tableBodyCell}>
                          {task.status}
                        </TableCell>

                        <TableCell align="right"
                          className="table__absolute-buttons table__absolute-buttons_padding_global">
                          <button className="anketa-button" onClick={showTask(task)}>Открыть</button>
                        </TableCell>
                    </>
                  }
                </TableRow>
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog
        open={showTaskModal}
        onClose={closeModal}
        classes={{
          paperWidthSm: classes.dialogWidth
        }}
      >
        {
          selectedTask &&
          <DialogContent style={{ paddingBottom: 20 }} className="TasksPage__dialog">
            <div style={{ marginBottom: 10 }}>Назначил</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.creator}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Исполнитель</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.implementer}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Дата назначения</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.appointmentDate}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Исполнить до</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.dueDate}
              disabled
            />

            <div style={{ marginBottom: 10 }}>ФИО Ребенка</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.childFio}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Потребности ребенка, выявленные при проведении всесторонней оценки</div>
            <TextField
              multiline={true}
              className={`${classes.textarea}`}
              rows={4}
              style={{ marginBottom: 30 }}
              value={selectedTask.requirenment}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Мероприятия по предоставлению ребенку находящемуся в тсж соответствующей помощи и услуг</div>
            <TextField
              multiline={true}
              className={`${classes.textarea}`}
              rows={4}
              style={{ marginBottom: 30 }}
              value={selectedTask.event}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Ожидайемый результат</div>
            <TextField
              className={`${classes.textField}`}
              variant="filled"
              style={{ marginBottom: 30 }}
              value={selectedTask.expectedResult}
              disabled
            />

            <div style={{ marginBottom: 10 }}>Отметка об исполнении/неисполнении (причина)</div>
            <TextField
              multiline={true}
              className={`${classes.textarea}`}
              rows={4}
              style={{ marginBottom: 30 }}
              defaultValue={selectedTask.comment}
              onChange={event => setComment(event.target.value)}
            />

            <div className="TasksPage__dialog-buttons">
              <Button className="Button Button--text" color="primary" disableElevation onClick={closeModal}>Закрыть</Button>
              <Button className="Button" color="primary" variant="contained" disableElevation onClick={updateTask}>Выполнено</Button>
            </div>
          </DialogContent>
        }
      </Dialog>
    </div>
  )
}
