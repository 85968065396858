import {
    GET_JSON,
    GET_JSON_FAILURE,
    GET_JSON_SUCCESS,
    CLEAR_STORE,
    GET_JSON_FOR_FILTER_SUCCESS, CANCEL_FILTER_CHOICE, FILTER_FIELD_CHANGE, CLOSE_FILTER_WINDOW, GET_SCHEMA_SUCCESS,
    CLOSE_SCHEMA_MODAL, GET_DATES_SUCCESS, SECOND_MODAL_TRIGGER, DATES_REPORT_GET_SUCCESS, CREATE_NEW_SCHEME, SET_LOADER, UPDATE_ACTIVE_STEP, GROUPS_FIELDS, SELECTED_QUESTIONARE_GET_SUCCESS, SEARCH_DB_RECORDS_SUCCESS
} from './mainTableActions';

const initialState = {
    data: [],
    loading: true,
    filter: [],
    filterOpen: false,
    schema: {},
    schemaOpen: false,
    activeSchemaId: '',
    dates: [],
    secondModalOpen: false,
    secondModalData: [],
    isNewScheme: false,
    loader: false,
    activeStep: 0,
    questionare: {},
    temp: null,
    selectedQuestionare: {},
    selectedQuestionareValues: {},
    pageObject: {}
}

export default (state = initialState, action) => {
    // console.log(state.activeSchemaId)
    switch (action.type) {
        case SET_LOADER:
            return {
                ...state,
                loader: action.loader
            }
        case GET_JSON:
            return {
                ...state,
                loading: true,
            }
        case GET_JSON_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                pageObject: action.pageObject
            }
        case GET_JSON_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case CLEAR_STORE:
            return {
                ...state,
                data: [],
                loading: true
            }
        case GET_JSON_FOR_FILTER_SUCCESS:
            return {
                ...state,
                filter: action.payload,
                filterOpen: true,
            }
        case CANCEL_FILTER_CHOICE:
            return {
                ...state,
                filter: action.payload,
                filterOpen: false,
            }
        case CLOSE_FILTER_WINDOW:
            return {
                ...state,
                filterOpen: false
            }
        case FILTER_FIELD_CHANGE:
            const {filter} = state;
            action.payload.forEach(id => {
                filter.result.fields[id].checked = !filter.result.fields[id].checked
            });
            return {
                ...state,
                filter
            }
        case GET_SCHEMA_SUCCESS:
            localStorage.setItem('422', action.id);
            const {payload: {result: {schema: {model, schema}}}, label, id} = action;

            const parsedSchema = [];
            const parsedModel = [];

            if (label) {
                delete label.model.childId;
            }

            const iterateObject = label ? label.model : model;


            Object.keys(iterateObject).forEach((oneModel, indexOfModel) => {
                schema.fields.forEach((oneSchema, indexOfSchema) => {
                    if (oneSchema.model === oneModel) {
                        parsedSchema.push(oneSchema)
                        parsedModel.push({
                            [oneModel]: iterateObject[oneModel],
                        });
                        schema.fields.splice(indexOfSchema, 1);
                    }
                });
            });

            return {
                ...state,
                schema: {
                    labels: label ? parsedModel : model,
                    models: [...parsedSchema, ...schema.fields],
                    nativeLabels: iterateObject,
                },
                schemaOpen: true,
                activeSchemaId: id
            }
        case CLOSE_SCHEMA_MODAL:
            return {
                ...state,
                schemaOpen: false
            }
        case SEARCH_DB_RECORDS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    models: [...action.payload.result.models]
                },
            }
        case GET_DATES_SUCCESS:
            return {
                ...state,
                dates: action.payload,
                isNewScheme: false
            }
        case SECOND_MODAL_TRIGGER:
            return {
                ...state,
                secondModalOpen: !state.secondModalOpen
            }
        case DATES_REPORT_GET_SUCCESS:
            const parsedSecondSchema = [];
            const parsedSecondModels = [];
            Object.keys(action.payload.result.model).forEach((oneModel, indexOfModel) => {
                action.payload.result.schema.fields.forEach((oneSchema, indexOfSchema) => {
                    if (oneSchema.model === oneModel) {
                        parsedSecondSchema.push(oneSchema)
                        parsedSecondModels.push({
                            [oneModel]: action.payload.result.model[oneModel],
                        });
                        action.payload.result.schema.fields.splice(indexOfSchema, 1);
                    }
                });
            });

            return {
                ...state,
                secondModalData: {
                    schemas: parsedSecondSchema,
                    models: parsedSecondModels,
                },
                questionare: {
                    fields: action.payload.result.schema.schema.fields
                },
                temp: action.payload,
            }
        case SELECTED_QUESTIONARE_GET_SUCCESS:
            return {
                ...state,
                selectedQuestionare: localStorage.getItem("time") ? action.payload.result.model  :  action.payload.result ,
            }

        case GROUPS_FIELDS:
            // console.log('%c action payload groups', "color: blue" , action.payload)
            const availableGroups = ['GeneralQuestionsChildAndFamily', 'Unit1', 'Unit2',
            'Unit3','Unit4','Unit5','Unit6','Unit7','Unit8','Unit9','Unit10', 'Unit11']
            const groupEntries1 = Object.entries(action.payload.result.groups).filter(item=>{
                // let availabe = availableGroups[0] || availableGroups[1]
                return item[1] === availableGroups[0] || item[1] === availableGroups[1]
            })
            // console.log("group1entries", groupEntries1)
            const groupKeys1 = groupEntries1.map(item=> {
                return item[0]
            })
            // console.log("groupKeys1", groupKeys1)
            // console.log(action.payload.result.schema.schema.fields);
            let group1 = action.payload.result.schema.schema.fields.filter( item => {
                let a = groupKeys1.filter(i => {
                        return i === String(item.model)
                    })
                return String(item.model) === a[0] 
                }
            )

            console.log('AAA', group1)

            group1.unshift({
                utocomplete: "off",
                disabled: false,
                inputType: "text",
                label: "Место рождения",
                label_en: "PlaceOfBirth",
                label_kg: "Место рождения",
                label_ru: "Место рождения",
                maxlength: "30",
                model: "text-MR5Osbkwm4uikHmoZMh6D",
                placeholder: "",
                required: false,
                survey: false,
                type: "input",
                unique: false,
            })
            // console.log("🚀 ~ file: mainTableReducer.js ~ line 151 ~ group1", group1)

            function createNewGroup(iteration) {
                let groupEntries = Object.entries(action.payload.result.groups).filter(item=>{
                    return item[1] === availableGroups[iteration]
                })
                const groupKeys = groupEntries.map(item=> {
                    return item[0]
                })
                // console.log("groupKeys", groupKeys)
                // console.log(action.payload.result.schema.schema.fields);
                let group = action.payload.result.schema.schema.fields.filter( item => {
                    let a = groupKeys.filter(i => {
                            return i === String(item.model)
                        })
                    return String(item.model) === a[0] 
                    }
                )
                return group;
            }

            let group2 = createNewGroup(2)
            let group3 = createNewGroup(3)
            let group4 = createNewGroup(4)
            let group5 = createNewGroup(5)
            let group6 = createNewGroup(6)
            let group7 = createNewGroup(7)
            let group8 = createNewGroup(8)
            let group9 = createNewGroup(9)
            let group10 = createNewGroup(10)
            let group11 = createNewGroup(11)

            return {
                ...state,
                questionare: {
                    ...action.payload.result.schema,
                    group1,
                    group2,
                    group3,
                    group4,
                    group5,
                    group6,
                    group7,
                    group8,
                    group9,
                    group10,
                    group11
                }
            }
        case CREATE_NEW_SCHEME:
            return {
                ...state,
                isNewScheme: true
            }
        case UPDATE_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.payload
            }

        default:
            return state;
    }
}
