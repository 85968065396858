import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AuthInn from "../features/Auth-inn"
import MainPage from '../features/Main-page'
import Tasks from '../features/Tasks'
import Table from '../features/Table'
import Sidebar from "./Sidebar"
import Signal from "../components/tables/signal"
import Identifying from "../components/tables/identifying/identifying"
import Questionnaire from "../features/Create-questionnaire/questionnaire"
import {useLocation} from 'react-router-dom'
import CreateSignal from '../features/Table/components/create-signal'
import { Menu } from '@material-ui/icons'
import useMedia from '@material-ui/core/useMediaQuery'
import TasksPage from '../pages/TasksPage'
import ReportPage from '../pages/ReportPage'

function App() {
    const isAuthorized = useSelector(store => store.authReducer.sessionId);
    let location = useLocation();
    const [open, setOpen] = React.useState(false)
    const matches = useMedia('(max-width: 900px)')

    if (!isAuthorized) { 
        return <AuthInn/>
    }


    // console.log(location)
    return (
        <>
            {
                (!location.pathname.startsWith('/create')) ? <div className="App-wrap">
                    <Sidebar open={open} setOpen={setOpen} />
                    <div className="content">
                        {matches &&
                            <Menu onClick={() => setOpen(true)} />
                        }
                        <Switch>
                            <Route exact path='/' component={MainPage}/>
                            <Route exact path='/table/tasks' component={TasksPage} />
                            <Route exact path='/table/report' component={ReportPage}/>
                            <Route exact path='/table/:id' component={Table}/>
                            <Route exact path='/signal' component={Signal}/>
                            <Route exact path='/identifying' component={Identifying}/>
                            <Route exact path='/tasks' component={Tasks}/>
                        </Switch>
                    </div>
                </div>
                : null}
            <Switch>
                <Route exact path='/create/questionnaire' component={Questionnaire}/>
                <Route exact path='/create/questionnaire/:id' component={Questionnaire}/>
                <Route exact path='/create/questionnaire/:id/:imageId' component={Questionnaire}/>
                <Route strict path='/create/signal/:id' component={CreateSignal}/>
                <Route strict path='/create/signal' component={CreateSignal}/>
            </Switch>
        </>
    );
}

export default App;
