import { combineReducers } from 'redux';
import authReducer from '../features/Auth-inn/accountsReducer';
import mainPageReducer from '../features/Table/mainTableReducer';
import tasksReducer from '../features/Tasks/taskReducer';

const rootReducer = combineReducers({
  authReducer,
  mainPageReducer,
  tasksReducer,
});

export default rootReducer;
