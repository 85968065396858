import React, {useState} from 'react';
import Drawer from "@material-ui/core/Drawer";
import useMedia from '@material-ui/core/useMediaQuery'
import {
    CategoryMainPageIcon,
    ChildrenIcon,
    IZPRICON,
    MenuSearchIcon,
    NotificationIcon,
    UserAvatar
} from "../icons/icons";
import { NavLink} from "react-router-dom";
import Logo from '../common/assets/Logo.svg'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import axios from '../common/api'

export const links = [
    {
        title: 'Сигнал',
        icon: <NotificationIcon/>,
        id: '274f0c5d-2c02-43b2-8d59-77e73b4494ea',
        description: 'Краткое описание секции раздела меню'
    },
    {
        title: 'Выявление',
        icon: <MenuSearchIcon/>,
        id: '918bc9db-a779-415f-8b4e-47b978228b17',
        description: 'Краткое описание секции раздела меню'
    },
    // {
    //     title: 'Дети',
    //     icon: <ChildrenIcon />,
    //     id: '918bc9db-a779-415f-8b4e-47b978228b17',
    //     description: 'Краткое описание секции раздела меню'
    // },
    {
        title: 'ИПЗР/ПИРС',
        icon: <IZPRICON/>,
        id: '7a868f4a-cded-49bf-8be2-1e0461423ebe',
        description: 'Краткое описание секции раздела меню'
    },
    {
        title: 'Дети в ТЖС',
        icon: <ChildrenIcon/>,
        id: '9d7c9150-265f-42bc-b2ce-bb706d7408fc',
        description: 'Краткое описание секции раздела меню'
    },
    {
        id: 'tasks',
        title: 'Задачи',
        icon: <CategoryMainPageIcon />,
    },
    {
        id: 'report',
        title: 'Отчетность',
        icon: <CategoryMainPageIcon />,
    },
];
const Sidebar = props => {
    const [userInterface, setUserInterface] = useState(false);
    // const history = useHistory();

    const [name, setName] = useState(null)

    const matches = useMedia('(max-width: 900px)')

    const toggleInterfaceClick = () => {
        setUserInterface(!userInterface)
    };
    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    React.useEffect(() => {
        axios
            .get('/api/employee/info')
            .then(res => setName(res.data.name))
    }, [])

    return (
        <Drawer
            variant={matches ? null : 'permanent'}
            open={props.open}
            onClose={() => props.setOpen(false)}
            className="menuDrawer">
            <div className="logo">
                <img src={Logo} alt='logo' />
            </div>
            <div className="sidebar__content">
                <ul>
                    <li>
                        <NavLink isActive={(_, {pathname}) => '/' === pathname} to="/" onClick={() => props.setOpen(false)}>
                            <div>
                                <CategoryMainPageIcon/>
                                <span>Главная</span>
                            </div>
                        </NavLink>
                    </li>
                    {links.map((elem, idx) => (
                        <li key={idx}>
                            <NavLink exact key={elem.id} to={`/table/${elem.id}`} onClick={() => props.setOpen(false)}>
                                <div>
                                    {elem.icon}
                                    <span>{elem.title}</span>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div className="user">
                    <div className="user__avatar" style={{minWidth: 43}}>
                        <UserAvatar/>
                    </div>
                    <div className="user__info" onClick={toggleInterfaceClick}>
                        <p className="user__info-name">{name}</p>
                        {/* <p className="user__info-work">Должность</p> */}
                    </div>
                    {
                        <LogoutDialog open={userInterface} setOpen={setUserInterface} logout={logout} /> 
                    }
                    {/* {userInterface && <div className="user__interface">
                        <div onClick={logout}>Выйти</div>
                    </div>} */}
                </div>
            </div>
        </Drawer>
    );
};

const LogoutDialog = ({ open, setOpen, logout }) => {
    const handleClose = () => {
        setOpen(false);
    };
    
    return (
      <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{"Выход"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
        <span style={{padding: '0 20px', display: 'block'}}>
         Вы дейтствительно хотите выйти?
        </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Отмена
        </Button>
        <Button onClick={logout} color="primary" autoFocus>
          Выйти
        </Button>
      </DialogActions>
    </Dialog>
    )
  }


export default Sidebar;
