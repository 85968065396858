import {GET_TASKS_SUCCESS, GET_TASKS, OPEN_ONE_TASK,CLEAR_ONE_TASK} from './taskActions';

const initialState = {
  tasks: [],
  oneTask: null,
  loading: 'nothing', // loading, loadingEnded, nothing
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS:
      return {
        ...state,
        loading: 'loading'
      }
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
        loading: 'loadingEnded',
      }
    case OPEN_ONE_TASK:
      return {
        ...state,
        oneTask: state.tasks[action.payload]
      }
    case CLEAR_ONE_TASK:
      return {
        ...state,
        oneTask: null
      }
    default:
      return state
  }
};
