import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  input: {
    height: 40,
  },
  header: {
    padding: `${theme.spacing(4)}px 0`,
    flexGrow: 1,
    '& .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal': {
      margin: '0'
    }
  },
  header__action_wrap: {
    display: 'flex',
    alignItems: 'center',
  },
  header__title: {
    fontWeight: 700,
    fontSize: '30px',
    color: '#000',
    margin: 0,
    // marginLeft: theme.spacing(1)
  },
  header__search: {
    background: '#fff',
    padding: 0,
    '& > div': {
      borderRadius: '4px 0 0 4px',
      borderWidth: '0 !important',
      borderColor: '#fff'
    }
  },
  header__search_input: {
    height: '20px'
  },
  header__search_button: {
    background: theme.palette.lightBlue.main,
    height: '40px',
    boxShadow: 'none !important',
    borderRadius: '0 4px 4px 0',
    '&:hover': {
      background: theme.palette.blue.main
    }
  },
  header__filter_button: {
    textTransform: 'none'
  },
  header__filter_icon: {
    marginRight: theme.spacing(1)
  },
  header__add_post: {
    background: theme.palette.lightBlue.main,
    fontWeight: 600,
    height: '40px',
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.blue.main
    }
  },
  header__add_post_icon: {
    marginRight: theme.spacing(1)
  },
  header__form: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline'
  },
  header__label_text: {
    color: theme.palette.dark.main,
    marginBottom: '10px'
  },
  header__select: {
    width: '100%',
    minWidth: '200px'
  },
  header__adornment: {
    position: 'absolute',
    right: '0'
  },
  header__adornment_button: {
    padding: '9px',
    marginRight: '5px'
  },
  header__input_icon: {
    width: '24px',
    height: '24px'
  },
  header__form_control: {
    marginRight: '20px'
  }
}));

export default useStyles;
