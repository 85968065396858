import axios from "../../common/api";

import url from "../../common/urls";

export const GET_JSON = "GET_JSON";
export const GET_JSON_FAILURE = "GET_JSON_FAILURE";
export const GET_JSON_SUCCESS = "GET_JSON_SUCCESS";
export const CLEAR_STORE = "CLEAR_STORE";
export const GET_JSON_FOR_FILTER_SUCCESS = "GET_JSON_FOR_FILTER_SUCCESS";
export const GET_JSON_FOR_FILTER_FAILURE = "GET_JSON_FOR_FILTER_FAILURE";
export const CANCEL_FILTER_CHOICE = "CANCEL_FILTER_CHOICE";
export const FILTER_FIELD_CHANGE = "FIELD_CHANGE";
export const CLOSE_FILTER_WINDOW = "CLOSE_FILTER_WINDOW";
export const GET_SCHEMA_SUCCESS = "GET_SCHEMA_SUCCESS";
export const CLOSE_SCHEMA_MODAL = "CLOSE_SCHEMA_MODAL";
export const GET_DATES_SUCCESS = "GET_DATES_SUCCESS";
export const SECOND_MODAL_TRIGGER = "SECOND_MODAL_TRIGGER";
export const DATES_REPORT_GET_SUCCESS = "DATES_REPORT_GET_SUCCESS";
export const CREATE_NEW_SCHEME = "CREATE_NEW_SCHEME";
export const SET_LOADER = "SET_LOADER";
export const UPDATE_ACTIVE_STEP = "UPDATE_ACTIVE_STEP";
export const GROUPS_FIELDS = "GROUPS_FIELDS";
export const GET_GROUPS = "GET_GROUPS";
export const SELECTED_QUESTIONARE_GET_SUCCESS =
  "SELECTED_QUESTIONARE_GET_SUCCESS";
export const SEARCH_DB_RECORDS_SUCCESS = "SEARCH_DB_RECORDS_SUCCESS";

export const setLoader = (loader) => ({
  type: SET_LOADER,
  loader,
});

export const changedNewScheme = () => ({
  type: CREATE_NEW_SCHEME,
});

export const getDatesReportSuccess = (payload) => ({
  type: DATES_REPORT_GET_SUCCESS,
  payload,
});

export const getSelectedQuestionareSuccess = (payload) => ({
  type: SELECTED_QUESTIONARE_GET_SUCCESS,
  payload,
});

export const groupsFields = (payload) => ({
  type: GROUPS_FIELDS,
  payload,
});

export const secondModalTrigger = () => ({
  type: SECOND_MODAL_TRIGGER,
});

export const getDatesSuccess = (payload) => ({
  type: GET_DATES_SUCCESS,
  payload,
});

export const closeSchemaModal = () => ({
  type: CLOSE_SCHEMA_MODAL,
});

export const getSchemaSuccess = (payload, label, id) => ({
  type: GET_SCHEMA_SUCCESS,
  payload,
  label,
  id,
});

export const filterFieldChange = (arrayOfId) => ({
  type: FILTER_FIELD_CHANGE,
  payload: arrayOfId,
});

export const filterWindow = () => ({
  type: CLOSE_FILTER_WINDOW,
});

export const cancelFilterChoice = (payload) => ({
  type: CANCEL_FILTER_CHOICE,
  filter: false,
  payload,
});

export const getJson = () => ({
  type: GET_JSON,
});

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export const getJsonForFilterSuccess = (payload) => ({
  type: GET_JSON_FOR_FILTER_SUCCESS,
  payload,
});

export const getJsonForFilterFailure = () => ({
  type: GET_JSON_FOR_FILTER_FAILURE,
});

export const getJsonSuccess = (payload) => {
  const {
    result: { labels },
  } = payload;

  const arrayOfId = payload.result.models.map((item) => item.id);
  const parsedModels = payload.result.models.map((obj) => {
    for (let key in obj.model) {
      if (obj.model[key] === "й") {
        obj.model[key] = "";
      }
    }
    return obj;
  });
  return {
    type: GET_JSON_SUCCESS,
    payload: {
      models: parsedModels,
      headers: labels,
      arrayOfId,
    },
    pageObject: payload.result.pageObject,
  };
};

export const getJsonFailure = () => ({
  type: GET_JSON_FAILURE,
});

export const getJsonThunk = (id) => (dispatch) => {
  dispatch(getJson());
  axios
    .get(`${url.GET_DB_RECORDS_LIST_URL}?formId=${id}`)
    .then((res) => {
      dispatch(getJsonSuccess(res.data));
    })
    .catch((err) => dispatch(getJsonFailure()));
};

export const getJsonPagination =
  (id, page = 1, size = 20) =>
  (dispatch) => {
    dispatch(getJson());
    axios
      .get(
        `${url.GET_DB_RECORDS_LIST_URL}?formId=${id}&page=${page}&size=${size}`
      )
      .then((res) => {
        dispatch(getJsonSuccess(res.data));
      })
      .catch((err) => dispatch(getJsonFailure()));
  };

export const getFilterJsonThunk = (id) => (dispatch) => {
  axios
    .get(`${url.SET_USER_FORM_SETTINGS_URL_DETI}/${id}`)
    .then((res) => dispatch(getJsonForFilterSuccess(res.data)))
    .catch((e) => console.error(e));
};

export const sendFilterChoiceThunk = (id, data) => (dispatch) => {
  axios
    .post(`${url.SET_USER_FORM_SETTINGS_URL}/${id}`, { fields: data })
    .then((res) => {
      dispatch(filterWindow());
      dispatch(getJsonThunk(id));
    })
    .catch((e) => console.error(e));
};

export const getSchemaThunk = (id, label, schemaId) => (dispatch) => {
  axios
    .get(`${url.GET_SCHEMA_URL}/${id}?isMobile=true`)
    .then((res) => {
      if (label) {
        dispatch(getDatesThunk(schemaId));
      } else {
        dispatch(changedNewScheme());
      }
      dispatch(getSchemaSuccess(res.data, label, id));
    })
    .catch((e) => console.error(e));
};

export const getDatesThunk = (id) => (dispatch) => {
  dispatch(setLoader(true));
  try {
    axios
      .get(`${url.GET_DATES}/${id}`)
      .then((res) => {
        dispatch(setLoader(false));
        dispatch(getDatesSuccess(res.data));
      })
      .catch((e) => console.error(e));
  } catch (e) {
    console.log(e.message);
  }
};

export const postChangedSchemaThunk = (id, pageId, data) => (dispatch) => {
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] === "Новый_ИПЗР") {
        data[key] = "Утвержденный_ИПЗР";
      }
    }
  }
  axios
    .post(`${url.POST_CHANGED_SCHEMA}/${id}`, data)
    .then((res) => {
      dispatch(closeSchemaModal());
      dispatch(getJsonThunk(pageId));
      if (res.status === 200) {
        window.open(
          `${
            window.location.origin
          }/api/db/GetIpzrPlan/${id}/${localStorage.getItem(
            "SmartId-SessionId"
          )}`,
          "_blank"
        );
      }
      // window.location.reload(true);
    })
    .catch((e) => {
      alert(e.response.data.message);
      console.error(e);
    });
};

export const postEditedSchema = (id, pageId, data, redirects) => (dispatch) => {
  axios
    .post(`${url.POST_CHANGED_SCHEMA}/${id}`, data)
    .then((res) => {
      dispatch(closeSchemaModal());
      dispatch(getJsonThunk(pageId));
      if (res.status === 200) {
        redirects();
      }
    })
    .catch((e) => console.error(e));
};

export const postEditedSchemaWithImage =
  (id, pageId, data, redirects, image, imageId, imageExist) => (dispatch) => {
    dispatch(postEditedSchema(id, pageId, data, redirects));

    let formData = new FormData();
    formData.append("File", image);
    console.log(formData);

    if (image) {
      if (imageExist) {
        axios
          .put(`${url.EDIT_CHILDREN_IMAGE}/${imageId}`, formData)
          .then((res) => {
            dispatch(closeSchemaModal());
            dispatch(getJsonThunk(pageId));
            if (res.status === 200) {
              redirects();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        axios
          .post(`${url.UPLOAD_CHILDREN_IMAGE}/${id}`, formData)
          .then((res) => {
            if (res.status === 200) {
              redirects();
            }
          });
      }
    }
  };

// export const getReportsDate = (id, date) => dispatch => {
//     axios.get(`${url.GET_RECORD_HISTORY}?recordId=${id}&dateTime=${date.split(' ').join('+')}`)
//         .then(res => {
//             dispatch(secondModalTrigger());
//             dispatch(getDatesReportSuccess(res.data))
//         })
//         .catch(e => console.error(e))
// }

export const getGroupsAction = (fields) => ({
  type: GET_GROUPS,
  payloda: fields,
});
export const getGroups = () => (dispatch) => {
  // axios.get(`${url.GET_RECORD_HISTORY}?recordId=${id}&dateTime=${date.split(' ').join('+')}`)
  // let fields = []
  // axios
  //   .get(`${url.GET_SCHEMA_HISTORY}/918bc9db-a779-415f-8b4e-47b978228b17`)
  //   .then((res) => {
  //     fields = res.data.result.schema.schema.fields
  //   })

  axios
    .get(`${url.GET_GROUPS_URL}`)
    .then((res) => {
      dispatch(groupsFields(res.data));
    })
    .catch((e) => console.error(e));
};

export const getReportsDate = (id) => (dispatch) => {
  // axios.get(`${url.GET_RECORD_HISTORY}?recordId=${id}&dateTime=${date.split(' ').join('+')}`)
  axios
    .get(`${url.GET_SCHEMA_HISTORY}/${id}`)
    .then((res) => {
      // dispatch(secondModalTrigger());
      // dispatch(groupsFields(res.data))
      dispatch(getGroups());
      dispatch(getDatesReportSuccess(res.data));
    })
    .catch((e) => console.error(e));
};

export const getTableItemValue = (id, date) => (dispatch) => {
  // axios.get(`${url.GET_RECORD_HISTORY}?recordId=${id}&dateTime=${date.split(' ').join('+')}`)
  axios
    .get(
      `${url.GET_RECORD_HISTORY}?recordId=${id}&dateTime=${date
        .split(" ")
        .join("+")}`
    )
    .then((res) => {
      console.log("DEBUG", res);
      dispatch(getSelectedQuestionareSuccess(res.data));
      dispatch(getGroups());
      dispatch(getDatesReportSuccess(res.data));
    })
    .catch((e) => console.error(e));
};

export const getSingleReport = (id) => (dispatch) => {
  axios
    .get(`${url.GET_DB_RECORD_ITEM}/${id}`)
    .then((res) => {
      // dispatch(groupsFields(res.data))

      dispatch(getGroups());
      dispatch(getSelectedQuestionareSuccess(res.data));
    })
    .catch((e) => console.error(e));
};

export const deleteChildImage = (id) => (dispatch) => {
  axios
    .delete(`${url.DELETE_CHILD_IMAGE}/${id}`)
    .then((res) => {
      console.log("image deleted: ", res.data);
    })
    .catch((e) => console.error(e));
};

export const postNewSchemeThunk = (id, data, redirect) => (dispatch) => {
  axios
    .post(`${url.CREATE_NEW_RECORD_IN_DB_URL}/${id}`, {
      ...data,
    })
    .then((res) => {
      dispatch(closeSchemaModal());
      if (res.status === 200) {
        redirect();
      }
    })
    .catch((e) => {
      alert(e.response.data.message);
      console.error(e);
    });
};

export const postNewSchemeThunkWithImage =
  (id, data, redirect, image) => (dispatch) => {
    // dispatch(postNewSchemeThunk(id, data, redirect))
    axios
      .post(`${url.CREATE_NEW_RECORD_IN_DB_URL}/${id}`, {
        ...data,
      })
      .then((res) => {
        if (res.status === 200) {
          redirect();
        }
        if (image) {
          let formData = new FormData();
          formData.append("File", image);
          axios
            .post(`${url.UPLOAD_CHILDREN_IMAGE}/${res.data.result}`, formData)
            .then((res) => {
              if (res.status === 200) {
                redirect();
              }
            });
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

export const updateActiveStep = (step) => ({
  type: UPDATE_ACTIVE_STEP,
  payload: step,
});

export const searchDbRecordsSuccess = (payload) => ({
  type: SEARCH_DB_RECORDS_SUCCESS,
  payload,
});

export const SearchDbRecords = (data) => (dispatch) => {
  axios
    .post(`${url.SEARCH_DB_RECORDS_URL}`, {
      ...data,
    })
    .then((res) => {
      dispatch(searchDbRecordsSuccess(res.data));
    })
    .catch((e) => console.error(e));
};
