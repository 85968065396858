import React, {useState} from 'react';
import {Checkbox} from "@material-ui/core";

const CustomCheckbox = ({check, onClick, onChange}) => {

    const [checked, setChecked] = useState(check);
    const changeState = () => {
        setChecked(!checked);
    }

    return (
        <Checkbox style={{
            // color: '#00ABE9',
            color: '#0e4ffa'
        }} checked={checked} onClick={changeState}/>
    )
}

export default CustomCheckbox;
