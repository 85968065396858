import React, {useEffect} from 'react';
import './tables.scss'
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
// import TablePagination from "@material-ui/core/TablePagination";
import Pagination from "@material-ui/lab/Pagination";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {AddRecordButtonIcon, ButtonSearchIcon} from "../../icons/icons";
import {getJsonThunk} from "../../features/Table/mainTableActions";
import {useSelector, useDispatch} from "react-redux";
// import parseJson from "../../features/Table/common/json-parse";
const Signal = () => {
    // const [signals, setSignals] = useState([]);
    const selector = useSelector(store => store.mainPageReducer.data);
    // useEffect(() => {
    //     if(selector.models) {

    //     }
    // }, [])
    // const obj = parseJson(selector.models, selector.headers);
    // console.log(obj)
    console.log(selector)
    const dispatch = useDispatch();
    let id = '274f0c5d-2c02-43b2-8d59-77e73b4494ea';
    const statuses = [
        {
            title: "Найден",
            color: "#32BD93"
        },
        {
            title: "Не обработан",
            color: "#FFA22C"
        },
        {
            title: "В процессе",
            color: "#6B92F0"
        }
    ];
    useEffect(() => {
        dispatch(getJsonThunk(id))
    }, [id, dispatch]);
    return (
        <div className="table">
            <div className="table__top">
                <div className="table__title">Сигнал</div>
                <div className="table__button">
                    <button>
                        <AddRecordButtonIcon />
                        <span>Новая запись</span>
                    </button>
                </div>
            </div>
            <div className="table__content">
                <div className="table__search">
                    <input type="text" placeholder="Поиск по ФИО, ИНН и другим параметрам"/>
                    <button>
                        <ButtonSearchIcon/>
                        <span>Поиск</span>
                    </button>
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Время</TableCell>
                                <TableCell>Кто сообщил</TableCell>
                                <TableCell>ФИО РЕБЕНКА</TableCell>
                                <TableCell>Место обнаружения</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Область</TableCell>
                                <TableCell>Город / Р-Н</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>1</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[0].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>2</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>МВД КР</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[1].color}}>
                                        <span>{statuses[1].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>3</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>МТСР</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[2].color}}>
                                        <span>{statuses[2].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>4</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>Мин.Обр. КР</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[0].color}}>
                                        <span>{statuses[2].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>5</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[1].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>6</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[1].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>7</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[2].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>8</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[0].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>9</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[1].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>10</TableCell>
                                <TableCell>
                                    <div className="date-table">
                                        <div>01/06/18</div>
                                        <span>18:20</span>
                                    </div>
                                </TableCell>
                                <TableCell>ОМС</TableCell>
                                <TableCell>Азаматов А.А.</TableCell>
                                <TableCell>ж/м Арча-Бешик, ул.<br/> Баймуратова</TableCell>
                                <TableCell>
                                    <div className="statuses" style={{backgroundColor: statuses[0].color}}>
                                        <span>{statuses[0].title}</span>
                                    </div>
                                </TableCell>
                                <TableCell>г. Бишкек</TableCell>
                                <TableCell>Первомайский района</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className="table-footer">
                    <Pagination count={10} color="primary"/>
                    <div className="selects">
                        <span>Записей на странице</span>
                        <Select variant="outlined">
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="10">20</MenuItem>
                            <MenuItem value="10">30</MenuItem>
                        </Select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signal;
