import axios from "../../common/api";
import url from "../../common/urls";

export const GET_INN = 'GET_INN';
export const GET_INN_FAILURE = 'GET_INN_FAILURE';
export const CHANGE_ORGANIZATION_NAME = 'CHANGE_ORGANIZATION_NAME';
export const CLEAR_EMPLOYEE_NAME = 'CLEAR_EMPLOYEE_NAME';
export const GET_INN_FROM_SERVER = 'GET_INN_FROM_SERVER';
export const PIN_CODE_CLEAR = 'PIN_CODE_CLEAR';
export const GET_SESSIONID = 'GET_SESSIONID';
export const GET_EMPLOYEE_INN = 'GET_EMPLOYEE_INN';
export const getSessionId = (sessionId) => ({
    type: GET_SESSIONID,
    sessionId
})
export const getInnAction = ({payload, name}) => ({
  type: GET_INN,
  payload,
  name
});

export const changeInn = () => ({
  type: CHANGE_ORGANIZATION_NAME
});

export const getInnFailure = (payload,field) => ({
  type: GET_INN_FAILURE,
  payload,
  field
})

export const getInnFromServer = payload => ({
  type: GET_INN_FROM_SERVER,
  payload
});

export const clearEmployeeName = () => ({
  type: CLEAR_EMPLOYEE_NAME,
});

export const getEmployeeInn = (payload,inn) => ({
  type: GET_EMPLOYEE_INN,
  payload,
  inn
});

export const pinCodeClear = () => ({
  type: PIN_CODE_CLEAR
})

export const getInnActionThunk = payload => dispatch => {
  axios.get(`${url.GET_INN_INFO}${payload}`)
    .then(res => {
      dispatch(getInnAction({payload,name: res.data.name}))
    })
    .catch(e => {
      dispatch(getInnFailure(e.response.data.message,'name'))
    })
};

export const innCheckThunk = inn => dispatch => {
  axios.post(`${url.CHECK_INN_URL}`, {
    inn
  })
    .then(res => {
      dispatch(getInnFromServer(res.data))
    })
    .catch(e => {
      console.log(e)
      // dispatch(getInnFailure(e.response.data.message,'name'))
    })
};

export const getEmployeeName = inn => dispatch => {
  axios.get(`${url.GET_INN_INFO}${inn}`)
    .then(res => {
      dispatch(getEmployeeInn(res.data.name,inn))
    })
    .catch(e => {
      dispatch(getInnFailure(e.response.data.message,'employeeName'))
    })
};

export const checkEmployeeInn = (innEmployee,innOrganisation) => dispatch => {
  axios.post(`${url.CHECK_BOTH_INN_URL}`,{
    innEmployee,
    innOrganisation,
  })
    .then(res => {
      dispatch(getInnFromServer(res.data))
    })
    .catch(e => {
      console.log(e);
      dispatch(getInnFailure(e.response.data.message,'employeeName'))
    })
};

export const checkPinCode = (innEmployee,innOrganisation,pin) => dispatch => {
  axios.post(`${url.GET_EMPLOYEE_TOKEN_URL}`,{
    innEmployee,
    innOrganisation,
    pin
  })
    .then(res => {
      dispatch(getSessionId(res.data.token))
      dispatch(getInnFromServer(res.data));
    })
    .catch(e => {
      dispatch(getInnFailure(e.response.data.message,'pin_message'))
      console.error(e)
    })
};
