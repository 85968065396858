import React from 'react'
import {Doughnut} from 'react-chartjs-2';
import "chartjs-plugin-datalabels";

const DoughnutChart = ({title, colors,chartData,labels,showDataLabel}) => {

    const state = {
        labels: labels,
        datasets: [
            {
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                data: chartData
            }
        ]
    };

    return (
        <>
            <div>
                <h3 className={"title"}>{title}</h3>
            </div>
            <Doughnut
                width="200px"
                height="200px"
                data={state}
                options={{
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 25
                        }
                    },
                    title: {
                        display: true,
                        fontSize: 20,
                        fontColor: "#000",
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            fontSize: 16,
                            fontColor: '#000',
                            boxWidth: 20,
                            hBoxMaxHeight: 50,
                            padding: 20,
                            generateLabels: function(chart) {
                                const data = chart.data;
                                if (data.labels.length && data.datasets.length) {
                                    return data.labels.map(function(label, i) {
                                        const ds = data.datasets[0];
                                        return {
                                            text: label + ": " +ds.data[i] ,
                                            fillStyle:colors[i],
                                        };
                                    });
                                }
                                return [];
                            }
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: showDataLabel,
                            align: 'end',
                            anchor:'end',
                            color: '#000',
                            font: {
                                size: 16,
                            },

                        },
                    }
                }}
            />
        </>
    )
}
export default DoughnutChart
