import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tableCell: {
    fontSize: '13px',
    fontWeight: 600,
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset'
    }
  },
  tableBodyCell: {
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset',
      display: 'flex',
      flexFlow: 'column',

      '& span': {
        color: 'gray'
      }
    }
  }
}));

export default useStyles;
