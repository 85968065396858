import React, {useEffect, useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Container,
} from '@material-ui/core';
import JsxParse from '../common/jsx-parse';
import '../common/style.css';
import {useSelector} from "react-redux";
import axios from '../../../common/api/index'

const SchemaModal = ({data: propsData, title, open, close, postChange, dates, isIPZR, activeId, openSecondModal, ...other}) => {
    const selector = useSelector(store => store.mainPageReducer.schema.nativeLabels);
    const ipzrDoc = false;
    const successIpzr = [];
    const [ipzrDataSuccess, setIpzrDataSuccess] = useState([]);
    const [isNewIpzr, setIsNewIpzr] = useState(false);
    const [objData, setObjData] = useState({});
    const [allChecked, setAllchecked] = useState(false)
    console.log(setAllchecked)
    let changedFields = selector;
    const changeField = (obj, init) => {
        const temp = Object.keys(obj);
        // changedFields[temp[0]] = obj[temp[0]];
        setObjData({...objData, [temp[0]]: obj[temp[0]]})
    };
    const [data, setData] = useState(propsData)

    useEffect(() => {
        // if (propsData.models) {
        //     const secondPartData = {
        //         ...propsData,
        //         models: propsData.models.filter(item => {
        //             return item.model !== 'text-WKTsB6oIA4WUtC1dsWXNb'
        //         })
        //     }

        //     const first = propsData.models.find(item => item.model === 'text-WKTsB6oIA4WUtC1dsWXNb')

        //     secondPartData.models.unshift(first)

            // setData(secondPartData)
        // }
        setData(propsData)
    }, [propsData])

    console.log(objData)
    useEffect(() => {
        // eslint-disable-next-line
        if (!open) setObjData({});
    }, [open]);
    useEffect(() => {
        if (isIPZR && Array.isArray(data.labels)) {
            data.labels.some(item => {
                if (item[Object.keys(item)] === 'Новый_ИПЗР') {
                    setIsNewIpzr(true)
                }
                return item;
            })
        }
        // eslint-disable-next-line
    }, [data.labels]);
    useEffect(() => {
        if (successIpzr.length) {
            successIpzr.forEach(el => data.models.forEach(ipz => {
                if (el[ipz.model]) {
                    setIpzrDataSuccess([...ipzrDataSuccess, ipz])
                }
            }))
        }
    }, [successIpzr, data.models, ipzrDataSuccess])
    const setClose = () => {
        close();
        setIpzrDataSuccess([])
    };
    let checkeds = ''

    return (
        <Dialog

            className="fullscreen__dialog"
            fullScreen
            draggable
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={open}
            {...other}
        >
            {/* <div className="QuestionsFamily__title">Вы добавите новую запись в сигнал</div> */}
            <Container maxWidth="md" className="add-signal__container">
                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent spacing={5} dividers>
                    {/*{isIPZR && <div>*/}
                    {/*    <FormControlLabel*/}
                    {/*        control={*/}
                    {/*            <Checkbox*/}
                    {/*                checked={allChecked}*/}
                    {/*                onChange={(e) => setAllchecked(e.target.checked)}*/}
                    {/*                name="checkedB"*/}
                    {/*                color="primary"*/}
                    {/*            />*/}
                    {/*        }*/}
                    {/*        label={allChecked ? "Удалить отмеченные": "Выбрать все"}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    {(data.models && open) && data.models.map((item, idx) => {
                        if (!(ipzrDoc && item.type === 'checkbox')) {
                            // if(allChecked) {
                            //     checkeds = {[item.model]: "да"};
                            // }
                            if (item.model === 'text-NSYBZ8P2HtUgHPRaodJgY' || item.model === 'text-WKTsB6oIA4WUtC1dsWXNb') return null
                            // if (item.model === 'text-NSYBZ8P2HtUgHPRaodJgY') return null

                            return <JsxParse objData={objData} setObjData={setObjData} changedFields={changedFields}
                                             item={item}
                                             checkedAll={checkeds}
                                             allChecked={allChecked}
                                             isNewIpzr={isNewIpzr}
                                             initialValue={data.labels[idx] || item.model}
                                             eventHandler={changeField} idx={idx}/>
                        }
                        return null
                    })}
                    {isIPZR && ipzrDataSuccess.map(el => {
                        return <div>{el.label}</div>
                    })}
                    {ipzrDoc && (
                        <div style={{
                            padding: '13px',
                            border: '1px solid',
                            marginTop: '10px'
                        }}>
                            <a rel="noopener noreferrer" className="dates__item"
                               href={`https://baldarbazasy.mlsp.gov.kg/api/db/GetIpzrPlan/${activeId}/${localStorage.getItem('SmartId-SessionId')}`} target='_blank'>
                                Открыть ИПЗР/ПИРС
                            </a>
                        </div>
                    )}
                    {/*<div className="dates">*/}
                    {/*    {*/}
                    {/*        dates.result && dates.result.map(item => <p className="dates__item"*/}
                    {/*                                                    onClick={() => openSecondModal(item)}*/}
                    {/*                                                    key={item}>{item.slice(0, -3)}</p>)*/}
                    {/*    }*/}
                    {/*</div>*/}
                    <DialogActions className="second-modal">
                        <Button className="second-modal__cancel" onClick={setClose} color="default">
                            Закрыть
                        </Button>
                        <Button className="second-modal__accept" color="primary" onClick={() => {
                            // if (!objData['date-5bcahBIcNhcsSBQw__va5'] || objData['date-5bcahBIcNhcsSBQw__va5'] === null) {
                            //     alert('Заполните поле - Область/Город республиканского значения')
                            //     return
                            // }
                            // console.log('Debug', objData)
                            // return

                            postChange(objData)
                        }}>
                            {isNewIpzr ? "Утвердить" : "Cохранить"}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Container>
        </Dialog>
    )
};

export default SchemaModal;
