import React from 'react';
// import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {AddRecordButtonIcon, ButtonSearchIcon} from "../../../icons/icons";
import {useHistory} from "react-router-dom";
// const useRowStyles = makeStyles({
//     root: {
//         '& > *': {
//             borderBottom: 'unset',
//         },
//     },
// });


function Row(props) {
    // const {row} = props;
    const [open, setOpen] = React.useState(false);
    // const classes = useRowStyles();
    const colors = [
        {
            bg: "rgba(58, 192, 159, 0.2)",
            color: "#3AC09F"
        },
        {
            bg: "rgba(100, 98, 202, 0.2)",
            color: "#6462CA"
        }
    ];
    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {props.index + 1}
                </TableCell>
                <TableCell align="right">12345</TableCell>
                <TableCell align="right">Азаматов А.А.</TableCell>
                <TableCell align="right">Мужской</TableCell>
                <TableCell align="right">+996 999 00 08 76</TableCell>
                <TableCell align="right">
                    <button className="anketa-button">Показать анкеты</button>
                </TableCell>
            </TableRow>
            <TableRow className="child-status" style={{width: "100%"}}>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Table aria-label="purchases">
                                <TableBody>
                                    <div className="identifying-status">
                                        {[1, 2, 3, 4, 5, 6, 7].map((a, i) => (
                                            <div className="identifying-status__item">
                                                <span>Анкета № {i}</span>
                                                <div
                                                    style=
                                                        {{
                                                            color: i % 3 === 0 ? colors[0].color : colors[1].color,
                                                            background: i % 3 === 0 ? colors[0].bg : colors[1].bg
                                                        }}
                                                >Статус
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


export default function CollapsibleTable() {
    const history = useHistory();
    return (
        <>
            <div className="table">
                <div className="table__top">
                    <div className="table__title">Выявление</div>
                    <div className="table__button">
                        <button onClick={() => history.push('/create/questionnaire')}>
                            <AddRecordButtonIcon />
                            <span>Новая анкета</span>
                        </button>
                    </div>
                </div>
                <div className="table__content">
                    <div className="table__search">
                        <input type="text" placeholder="Поиск по ФИО, ИНН и другим параметрам"/>
                        <button>
                            <ButtonSearchIcon/>
                            <span>Поиск</span>
                        </button>
                    </div>
                    <TableContainer>
                        <Table className="collapsible-table" aria-label="collapsible-table table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>№ </TableCell>
                                    <TableCell align="right">№ анкеты</TableCell>
                                    <TableCell align="right">ФИО РЕБЕНКА</TableCell>
                                    <TableCell align="right">Пол ребенка</TableCell>
                                    <TableCell align="right">Контактный номер</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((row, i) => (
                                    <Row row={row} index={i}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </>
    );
}
