import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emblem_wrap: {
    display: 'flex',
    alignItems: 'center'
  },
  emblem_wrap__img: {
    minWidth: '81px',
    minHeight: '81px',
    marginRight: theme.spacing(3),
  },
  emblem_wrap__text: {
    color: theme.palette.grey.main,
    fontWeight: '500',
    fontSize: '22px',
    letterSpacing: '0.148416px',

    '@media(max-width: 500px)': {
      fontSize: 12
    }
  },
}));

export default useStyles;
