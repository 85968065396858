import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import JsxParse from '../common/jsx-parse';

const SecondModal = ({data, open, close, ...other}) => {

  return (
    <Dialog
      draggable
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Выберите поля для открытия доступа</DialogTitle>
      <DialogContent dividers>
        {(data.schemas && data.schemas.length) && data.schemas.map((item, idx) => <JsxParse idx={idx} item={item} eventHandler={() => {}} initialValue={data.models[idx]}/>)}
        <DialogActions className="second-modal">
          <Button className="second-modal__cancel" onClick={close} color="default">
            Закрыть
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
};

export default SecondModal;
