import React from 'react'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ruLocale from 'date-fns/locale/ru'
import moment from 'moment'
import axios from '../common/api'
import { CircularProgress } from '@material-ui/core'
import regionsData from './regions.json'

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    },
  },
  textField: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    margin: '10px',
    // height: '48px',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& input': {
      // padding: '23px 12px 5px',
      fontSize: '15px',
      fontFamily: "Open Sans",
      color: "#333",
    },
    '& div': {
      borderRadius: '4px',
      background: 'inherit'
    },
    '& .MuiInputLabel-filled.MuiInputLabel-shrink': {
      transform: 'translate(12px, 5px) scale(0.75)',
      fontSize: '12px',
      top: '1px',
      color: '#333',
    },
    '& .MuiInputBase-input': {
      padding: '10px 12px'
    },
    '& label': {
      transform: 'translate(12px, 15px) scale(1)',
      fontFamily: "Open Sans",
      whiteSpace: 'nowrap',
      color: "#A2A1A6",
      fontSize: '15px',
    },
  },
  select: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    margin: '10px',
    width: '27%',
    height: '48px',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& div': {
      borderRadius: '5px',
    },
    '& label': {
      whiteSpace: 'nowrap',
      padding: '0 0 0 16px',
      zIndex: '1',
      top: '-9px',
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: '15px',
    },
    '& .MuiInputLabel-shrink': {
      top: '5px',
      color: '#333',
      fontSize: '12px'
    },
    '& .MuiFilledInput-input': {
      padding: '22px 12px 5px',
      fontSize: '15px',
      fontFamily: "Open Sans",

    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'inherit',
    }
  },

  date: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    fontFamily: "Open Sans",
    margin: '10px',
    height: '48px',
    width: '27%',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& .MuiInputBase-input': {
      padding: '7px 0 5px 13px',
      fontSize: '15px',
      color: '#333'
    },
    '& label': {
      padding: '0 0 0 16px',
      top: '-9px',
      whiteSpace: 'nowrap',
      fontFamily: "Open Sans",
      color: "#A2A1A6",
      fontSize: '15px',
    },
    '& .MuiInputLabel-shrink': {
      top: '5px',
      fontSize: '12px',
      color: '#333'
    },
  },

  textarea: {
    background: "#FCFCFC",
    border: "1px solid #E7E7E7",
    borderRadius: '5px',
    fontFamily: 'Roboto',
    margin: '10px',
    width: '27%',
    color: '#333',

    '@media (max-width: 900px)': {
      width: '100%',
      margin: '10px 0'
    },

    '& .MuiFormLabel-root': {
      // margin: 10
    },

    '& .MuiInputBase-multiline': {
      padding: '6px 5px 7px 10px',
      fontFamily: "Open Sans",
    },
    '& .MuiInputBase-inputMultiline': {
      fontSize: '15px',
      color: '#333',
      padding: '5px 0 5px 3px'
    },
    '&::placeholder': {
      color: 'black',
    }
  },


  tableCell: {
    fontSize: '13px',
    fontWeight: 600,
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset'
    }
  },
  tableBodyCell: {
    whiteSpace: 'nowrap',

    '@media(max-width: 900px)': {
      whiteSpace: 'unset',
      display: 'flex',
      flexFlow: 'column',

      '& span': {
        color: 'gray'
      }
    }
  },

  dialogWidth: {
    width: '100%',

    '& .MuiFormControl-root': {
      width: '100%',
      margin: 0
    }
  },

  selectMenuPaper: {
    minHeight: 0,

    '& .MuiList-padding': {
      padding: 0
    }
  }
})

export default function ReportPage() {
  const classes = useStyles()
  const [regions, setRegions] = React.useState(regionsData)
  const [region, setRegion] = React.useState(null)
  const [districts, setDistricts] = React.useState([])
  const [district, setDistrict] = React.useState(null)
  const [countrysides, setCountrysides] = React.useState([])
  const [countryside, setCountryside] = React.useState(null)
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [report, setReport] = React.useState(null)
  const matches = useMediaQuery('(max-width: 600px)')

  React.useEffect(() => {
    axios
      .get('/api/employee/info')
      .then(res => {
        if (res.data.isRepublicUser) {
          setRegions(regionsData.concat({
            region: 'Кыргызская Республика'
          }))
        }
      })
  }, [])

  const getReport = () => {
    if (!(region && startDate && endDate)) return alert('Поля области и даты обязательны')

    setLoading(true)

    const startD = moment(startDate).format("YYYY-MM-DD")
    const endD = moment(endDate).format("YYYY-MM-DD")

    axios
      .get(`/api/Statistic/GetAllStatistic`, {params: {
        startDate: startD,
        endDate: endD,
        region,
        district,
        imac: countryside
      }})
      .then(res => setReport(res.data.result))
      .finally(() => setLoading(false))
  }

  return (
    <div className="TasksPage ReportPage">
      <span
        style={{
          fontWeight: 600,
          fontSize: 28
        }}
      >
        Отчетность
      </span>

      <div className="ReportPage__heading">
        <div>
        <FormControl className={`${classes.select}`}>
          <InputLabel>Область</InputLabel>
          <Select
            name="status"
            value={region}
            onChange={event => {
              setRegion(event.target.value)

              const region = regions.find(region => region.region === event.target.value)

              if (region && region.districts) {
                setDistricts(region.districts)
                setCountrysides([])
              } else {
                setDistricts([])
                setCountrysides([])
              } 
            }}
            variant='filled'
          >
            {regions.map(option => <MenuItem
              value={option.region} key={option.region}>{option.region}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={`${classes.select}`} disabled={!districts.length}>
          <InputLabel>Район</InputLabel>
          <Select
            name="status"
            value={district}
            onChange={event => {
              setDistrict(event.target.value)

              const district = districts.find(district => district.district === event.target.value)

              if (district && district.countrysides) {
                setCountrysides(district.countrysides)
              } else {
                setCountrysides([])
              }
            }}
            variant='filled'
            MenuProps={{
              classes: {
                paper: classes.selectMenuPaper
              }
            }}
          >
            {districts.map(option => <MenuItem
              value={option.district} key={option.district}>{option.district}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={`${classes.select}`} disabled={!countrysides.length}>
          <InputLabel>Айылный аймак</InputLabel>
          <Select
            name="status"
            value={countryside}
            onChange={event => {
              setCountryside(event.target.value)
            }}
            variant='filled'
            MenuProps={{
              classes: {
                paper: classes.selectMenuPaper
              }
            }}
          >
            {countrysides.map(option => <MenuItem
              value={option.countryside} key={option.countryside}>{option.countryside}</MenuItem>)}
          </Select>
        </FormControl>
        </div>

        <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DatePicker
            variant="filled"
            className={`${classes.date}`}
            autoOk
            InputProps={{ disableUnderline: true }}
            fullWidth
            label="Период с:"
            format="yyyy-MM-dd"
            margin="normal"
            name="startDate"
            value={startDate ? moment(startDate).format("YYYY-MM-DD") : null}
            onChange={date => setStartDate(date)}
          />
        </MuiPickersUtilsProvider>

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <DatePicker
            variant="filled"
            className={`${classes.date}`}
            InputProps={{ disableUnderline: true }}
            autoOk
            fullWidth
            label="Период по:"
            format="yyyy-MM-dd"
            margin="normal"
            name="endDate"
            value={endDate ? moment(endDate).format("YYYY-MM-DD") : null}
            onChange={date => setEndDate(date)}
          />
        </MuiPickersUtilsProvider>
        </div>

        <Button style={{ marginLeft: 10, marginBottom: 10 }} color="primary" variant="contained" className="Button" disableElevation onClick={getReport}>Показать</Button>
      </div>

      {
        loading &&
        <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div>
      }

      {!loading && matches && report && 
        <div className="ReportCard">
          <div className="ReportCard__title">Общее количество детей в районе/городе</div>
          <div className="ReportCard__details">
            <div className="ReportCard__subtitle">{report?.childCount}</div>
          </div>
        </div>
      }
      
      {!loading && report && report.statistic && matches && report.statistic.map(reportItem => (
        <div className="ReportCard" key={reportItem.title}>
          <div className="ReportCard__title">{reportItem.title}</div>
          {reportItem.details &&
            <div className="ReportCard__details">
              {reportItem.details.map(detail => (
                <>
                  <div className="ReportCard__subtitle">{detail.title}</div>
                  <div className="ReportCard__value">{detail.value}</div>
                </>
              ))}
            </div>
          }
        </div>
      ))}

      {
        !loading && !matches && report &&
        <TableContainer component={Paper}>
        <Table className="ReportTable">
          <TableHead>
            <TableRow>
              <TableCell className="ReportTable__num">№</TableCell>
              <TableCell>Наименование категорий</TableCell>
              <TableCell>ежемесячный период</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(report.childCount !== null || report.childCount !== undefined) &&
              <TableRow>
                <TableCell className="ReportTable__total-cell-index">1</TableCell>
                <TableCell className="ReportTable__total-cell-title">Общее количество детей в районе/городе</TableCell>
                <TableCell className="ReportTable__total-cell-count">{report.childCount}</TableCell>
              </TableRow>
            }

            {report.statistic && report.statistic.map((reportItem, index) => (
              <>
                <TableRow key={reportItem.title}>
                  <TableCell rowSpan={reportItem.details ? reportItem.details.length + 1 : 1} className="ReportTable__index">{index + 2}</TableCell>
                  <TableCell>
                    <span className="ReportTable__title">{reportItem.title}</span>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {reportItem.details && reportItem.details.map(detail => (
                  <TableRow key={detail.title}>
                    <TableCell className="ReportTable__inner">{detail.title}</TableCell>
                    <TableCell className="ReportTable__inner">{detail.value}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
        </TableContainer>
      }

    </div>
  )
}

