import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import axios from '../../common/api';
import urls from '../../common/urls';
// import useStyles from './style';
import Typography from "@material-ui/core/Typography";
import {MainCalendarIcon} from "../../icons/icons";
import DoughnutChart from "../../Main/Doughnut";
import MainTop from "./components/MainTop";
import { Line} from "react-chartjs-2";
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// import * as moment from 'moment';
import format from "date-fns/format";
import ruLocale from "date-fns/locale/ru";
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn'

const monthValues = {
    narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
    abbreviated: [
        "янв.",
        "фев.",
        "март",
        "апр.",
        "май",
        "июнь",
        "июль",
        "авг.",
        "сент.",
        "окт.",
        "нояб.",
        "дек."
    ],
    wide: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
    ]
};

ruLocale.localize.month = buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
    defaultFormattingWidth: 'wide'
})

class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
        return format(date, "LLLL", {locale: this.locale});
    }

    getDatePickerHeaderText(date) {
        return format(date, "dd MMMM", {locale: this.locale});
    }
}


const MainPage = () => {
        // const classes = useStyles();
        const [user, setUser] = useState(null);
        console.log("user", user)
        const [selectedDate, setSelectedDate] = useState(new Date());
        console.log("selectedDate", selectedDate)
        const [openCalendar, setOpenCalendar] = useState(false)
        console.log("openCalendar", openCalendar)
        const [serverData, setServerData] = useState([])
        const [circle,setCircle] = useState([])
        console.log("serverData", serverData)
        const [indicators, setIndicators] = useState({})
        console.log("indicators", indicators)
        const [map, setMap] = useState([])
        const [total, setTotal] = useState([2,34,5,16])

        useEffect(() => {
            axios.get(urls.GET_NAME_BY_TOKEN_URL)
                .then(res => setUser(res.data))
                .catch(e => console.error(e));
        }, []);

        useEffect(() => {
            axios.get(`${urls.GET_MAIN_STATS}/${selectedDate.getUTCFullYear()}.${selectedDate.getUTCMonth() + 1 < 10 ? ('0'+(+selectedDate.getUTCMonth() + 1)) : (selectedDate.getUTCMonth() + 1)}`)
            .then(res => {
              if (JSON.stringify(res.data.result.circle) === JSON.stringify([0,0,0,0])) {
                setCircle([10,8,9,4])
              } else {
                setCircle(res.data.result.circle)
              }
              setServerData(res.data.result)
              setIndicators(res.data.result.indicators)
              setMap(res.data.result.map)
              setTotal(res.data.result.total)
            //   setTotal([{title: "Сигнал", value: 34, percent: 15},
            //   {title: "Выявление", value: 12, percent: -20},
            //   {title: "Дети в  ТЖС", value: 10, percent: 45},
            //   {title: "ИПЗР", value: 5, percent: 40}])
            })
            .catch(e => console.error(e));
        }, [selectedDate])

        const handleSelectedDate = (date) => {
            setSelectedDate(date)
        }

        const legend = {
            display: true,
            align: "end",
            position: "top",
            labels: {
                fontColor: "#323130",
                fontSize: 14
            }
        };

        const options = {
            title: {
                display: true,
                text: "Годовые показатели",
                position: "left"
            },
            plugins: {
                datalabels: {
                    display: false,
                }
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 5
                        },
                        gridLines: {
                            borderDash: [2, 8],
                        }
                    }
                ],
                xAxes: [{
                    gridLines: {
                        display: false
                    }
                }],
            }
        };
        const data = {
            labels: ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК",],
            datasets: [
                {
                    label: "Дети с ПИН",
                    data: indicators.data1,
                    // data: [1,2,4,12,10,8,9,6,8,7,6,5],
                    fill: true,
                    backgroundColor: "rgba(14, 79, 250, 0.9)",
                    pointRadius: 0,
                    borderColor: "rgba(75,192,192,1)"
                },
                {
                    label: "Дети без ПИН",
                    data: indicators.data2,
                    // data: [2,7,8,3,5,9,12,10,12,10,9,2],
                    fill: true,
                    pointRadius: 0,
                    backgroundColor: "rgba(30, 191, 152, 0.9)",
                    borderColor: `rgba(30, 191, 152, 0.9)`
                }
            ]
        };
        return (
            <div className="MainPage">
                <Grid xs justify='space-between' className='d-flex'>
                    <Typography className="mainTitle">Главная</Typography>
                    <MuiPickersUtilsProvider
                        utils={RuLocalizedUtils}
                        locale={ruLocale}
                    >
                        <div className="main__date-pick"
                             onClick={() => setOpenCalendar(openCalendar => !openCalendar)}
                        >
                            <MainCalendarIcon
                            />
                            <DatePicker
                                fullWidth
                                disableFuture
                                openTo="year"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                format="MMMM yyyy"
                                label=""
                                views={["year", "month"]}
                                value={selectedDate}
                                onChange={(e) => {
                                    handleSelectedDate(e)
                                }}
                                // formatDate={(date) => moment(date).format('YYYY-MM')}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </Grid>
                <MainTop map={map} total={total}/>
                <div className="charts__Wrap">
                    <div className="bottom-charts">
                        <div className="bottom-charts__title">
                            Случаи выявления в Кыргызстане
                        </div>
                        <DoughnutChart chartData={circle}
                                       labels={['Мин. Обр. КР', 'МТСР', 'ГРС при ПКР',  'МВД КР']}
                                       colors={['#7DA1FF', '#4C3689', '#35C6A2', '#DF58C1']} showDataLabel={true}/>
                    </div>
                    <div className="charts__line">
                        <div className="bottom-charts__title">
                            Случаи выявления в Кыргызстане
                        </div>
                        <Line data={data} legend={legend} options={options}/>
                    </div>
                </div>
            </div>
        )
    }
;

export default MainPage;
