import React, {useState} from 'react';
import ruLocale from "date-fns/locale/ru";
import {useDispatch, useSelector} from "react-redux";
import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button, TableContainer,
  LinearProgress,
  Paper, TableHead, TableRow, TableCell, TableBody, Table
} from "@material-ui/core";
import TaskModal from './components/Modal';
import {
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import moment from "moment";
import DateFnsUtils from '@date-io/date-fns';
import {getTasksThunk, openOneTask, clearOneTask} from './taskActions';
import useStyles from './style';
import arrow from "../Table/assets/Arrow - Left.png";
import eye from './assets/eye.png';
import BootstrapInput from './components/input';

const Tasks = ({history}) => {

  const [status, setStatus] = useState('New');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const store = useSelector(store => store.tasksReducer);
  const dispatch = useDispatch();
  const classes = useStyles();

  const getTasks = () => dispatch(getTasksThunk(`?status=${status}&startDate=${moment(startDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`));

  return (
    <Container>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
        <header className={classes.header}>
          <Grid container spacing={3}>
            <Grid xs={3} item alignItems='center' style={{paddingLeft: 0}} className={classes.flex}>
              <div className={classes.header__action_wrap}>
                <IconButton onClick={history.goBack}><img src={arrow} alt="Иконка назад"/></IconButton>
                <p className={classes.header__title}>Задачи</p>
              </div>
            </Grid>
            <Grid xs={12} item alignItems='center' className={classes.flex}>
              <form className={classes.header__form}>
                <FormControl className={classes.header__form_control}>
                  <InputLabel style={{fontWeight: 'bold'}} className={classes.header__label_text}>Статус</InputLabel>
                  <Select
                    value={status}
                    onChange={event => setStatus(event.target.value)}
                    className={classes.header__select}
                    label={'Статус'}
                    input={<BootstrapInput/>}
                  >
                    <MenuItem value={"New"}>Новая</MenuItem>
                    <MenuItem value={"Done"}>Выполнено</MenuItem>
                  </Select>
                </FormControl>

                <FormControl className={classes.header__form_control}>
                  <InputLabel style={{fontWeight: 'bold'}}>Период с</InputLabel>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    autoOk
                    format="dd/MM/yyyy"
                    margin="normal"
                    TextFieldComponent={props => <BootstrapInput {...props}/>}
                    endAdornment={
                      <InputAdornment className={classes.header__adornment}>
                        <IconButton className={classes.header__adornment_button}>
                          <svg className={classes.header__input_icon} focusable="false" viewBox="0 0 24 24"
                               aria-hidden="true">
                            <path
                              d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                          </svg>
                        </IconButton>
                      </InputAdornment>
                    }
                    value={startDate}
                    onChange={date => setStartDate(date)}
                  />
                </FormControl>

                <FormControl className={classes.header__form_control}>
                  <InputLabel style={{fontWeight: 'bold'}}>Период по</InputLabel>
                  <DatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    TextFieldComponent={props => <BootstrapInput {...props}/>}
                    format="MM/dd/yyyy"
                    margin="normal"
                    endAdornment={
                      <InputAdornment className={classes.header__adornment}>
                        <IconButton className={classes.header__adornment_button}>
                          <svg className={classes.header__input_icon} focusable="false" viewBox="0 0 24 24"
                               aria-hidden="true">
                            <path
                              d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                          </svg>
                        </IconButton>
                      </InputAdornment>
                    }
                    value={endDate}
                    onChange={date => setEndDate(date)}
                  />
                </FormControl>
                <FormControl>
                  <Button onClick={getTasks} variant="contained" size="large" color="primary"
                          className={classes.header__add_post}>
                    Показать
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </header>
      </MuiPickersUtilsProvider>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            {store.loading === 'loading' && <LinearProgress/>}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>№</TableCell>
                  <TableCell className={classes.tableCell}>ФИО ребенка</TableCell>
                  <TableCell className={classes.tableCell}>Назначено</TableCell>
                  <TableCell className={classes.tableCell}>Срок исполнения</TableCell>
                  <TableCell className={classes.tableCell}>Статус</TableCell>
                  <TableCell className={classes.tableCell}>Действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {store.tasks.length ? (
                  store.tasks.map((item, idx) => (
                    <TableRow key={idx} hover>
                      <TableCell>{idx + 1}</TableCell>
                      <TableCell>{item.childFio}</TableCell>
                      <TableCell>{item.implementer}</TableCell>
                      <TableCell>{moment(item.completionDate).format('YYYY-MM-DD')}</TableCell>
                      <TableCell>{item.status}</TableCell>
                      <TableCell style={{display: 'flex', alignItems: 'center'}}
                                 onClick={() => dispatch(openOneTask(idx))}>
                        <img src={eye} alt='eye icon' style={{marginRight: '10px'}}/>
                        <span
                          style={{color: '#00ABE9', textDecoration: 'underline', cursor: 'pointer'}}>Посмотреть</span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <>
                    <TableCell/>
                    <TableCell/>
                    <TableCell style={{textAlign: 'right'}}>
                      <span>Нет данных для отображения</span>
                    </TableCell>
                    <TableCell/>
                    <TableCell/>
                    <TableCell/>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <TaskModal setOpen={() => dispatch(clearOneTask())} data={store.oneTask}/>
    </Container>
  )
}

export default Tasks;
