import React from 'react';
import emblem from "../../assets/emblem.png";
import useStyles from "./style";

const Emblem = () => {

  const classes = useStyles();

  return (
    <div className={classes.emblem_wrap}>
      <img src={emblem} className={classes.emblem_wrap__img} alt="Герб Кыргызской республики"/>
      <p className={classes.emblem_wrap__text}>
        Министерство труда <br />
        и социального развития <br />
        Кыргызской республики
      </p>
    </div>
  )
}

export default Emblem;
