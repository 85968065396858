import React, {useEffect, useState} from "react";
import {Button, makeStyles, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import JsxParse from "../Table/common/jsx-parse";
import {updateActiveStep} from "../Table/mainTableActions";
import photo from "../../common/assets/photo.svg";
import useMedia from '@material-ui/core/useMediaQuery'
import regions from '../../pages/regions.json'

const useStyles = makeStyles({
    select: {
        background: "#FCFCFC",
        border: "1px solid #E7E7E7",
        borderRadius: '5px',
        margin: '10px',
        width: '31%',
        height: '48px',
        overflow: 'hidden',

        '@media (max-width: 900px)': {
            width: '100%',
            margin: '10px 0'
        },

        '& div': {
            borderRadius: '5px',
        },
        '& label': {
            whiteSpace: 'nowrap',
            padding: '0 0 0 16px',
            zIndex: '1',
            top: '-9px',
            fontFamily: "Open Sans",
            color: "#A2A1A6",
            fontSize: '15px',
        },
        '& .MuiInputLabel-shrink': {
            top: '5px',
            color: '#333',
            fontSize: '12px'
        },
        '& .MuiFilledInput-input': {
            padding: '22px 12px 5px',
            fontSize: '15px',
            fontFamily: "Open Sans",

        },
        '& .MuiFilledInput-root': {
            backgroundColor: 'inherit',
        }
    },
})

const QuestionsFamily = ({
                             changeField,
                             filledData,
                             steps,
                             preview,
                             setPreview,
                             selectedFile,
                             setSelectedFile,
                             id,
                             imageId,
                             imageSrc,
                             setShowExistImage,
                             showExistImage
                         }) => {
    const selector = useSelector((store) => store.mainPageReducer);
    const activeStep = useSelector((store) => store.mainPageReducer.activeStep);
    const dispatch = useDispatch();
    const styles = useStyles()
    // const [groupData,setGroupData] = useState([])

    const [region, setRegion] = useState(filledData['select-aeozhPQSFy4YYLNlXUbJr'])
    const [district, setDistrict] = useState(filledData['select-URRVEdr_Hjzbdu1O_DtS9'])
    const [districts, setDistricts] = useState([])
    const [countryside, setCountryside] = useState(filledData['select-kylqALnvX-F-2ZYXe_-NZ'])
    const [countrysides, setCountrysides] = useState([])
    const [village, setVillage] = useState(filledData['select-NYpNvsrNLkdbkSIC66bRI'])
    const [villages, setVillages] = useState([])



    // * create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // * free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
            return;
        }

        // let reader = new FileReader();
    
        // reader.onload = function(e) {
        //     setPreview(e.target.result)
        // }
        
        // reader.readAsDataURL(e.target.files[0]);

        setSelectedFile(e.target.files[0]);
    };

    // const handleClick = () => {
    //     handleNext()
    // }

    // useEffect(()=> {
    //     // const groupName = 'group' + (activeStep + 1)
    //     let activeGroup = selector.questionare[`group${activeStep + 1}`] || selector.questionare.group1
    //     setGroupData(activeGroup)
    // }, [activeStep, groupData])

    const incDecActiveStep = (toggleValue) => {
        dispatch(updateActiveStep(activeStep + toggleValue));
    };

    const closeOrDeleteImage = () => {
        // if (imageId) {
        //   dispatch(deleteChildImage(imageId))
        // }
        setSelectedFile(null)
        setPreview('')
    }

    // const DeleteExistImage = () => {
    //   // dispatch(deleteChildImage(imageId))
    //   setShowExistImage(false)
    // }
    const matches = useMedia('(max-width: 900px)')

    return (
        <div className="QuestionsFamily">
            <div className="QuestionsFamily__title">{steps[activeStep]}</div>
            <div className="content__border content__border_margin">
                <div className="form-title">Информация о ребенке</div>
                <form>
                    <div className="field">
                        <div className="field__wrap">
                            {activeStep === 0 ? (
                                <>
                                    <div className="w-100 d-flex">
                                        <div className="photo_file">
                                            <div>
                                                <img src={photo} alt="icon"/>
                                                <label for="file">Добавьте фото ребенка</label>
                                                <input
                                                    type="file"
                                                    id="file"
                                                    style={{display: "none"}}
                                                    onChange={onSelectFile}
                                                />
                                            </div>
                                        </div>
                                        {selectedFile &&  (
                                            <div className="photo_file ">
                                                <div>
                                                    <img src={preview} className="photo_file__preview" alt='preview'/>
                                                    <div className='photo_file-cross' onClick={closeOrDeleteImage}>+
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!selectedFile && imageSrc &&  (
                                            <div className="photo_file ">
                                                <div>
                                                    <img src={imageSrc} className="photo_file__preview" alt='preview'/>
                                                    {/* <div className='photo_file-cross' onClick={DeleteExistImage}>+</div> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : null}

                            {selector.questionare[`group${activeStep + 1}`] &&
                                    selector.questionare[`group${activeStep + 1}`].length &&
                                    selector.questionare[
                                        `group${activeStep + 1}`
                                    ].map((item, idx) => (
                                        <JsxParse
                                            idx={idx}
                                            item={item}
                                            eventHandler={changeField}
                                            initialValue={item.model}
                                            serverData={filledData}
                                            key={item.model}
                                            regions={regions}
                                            setRegion={setRegion}
                                            district={district}
                                            setDistrict={setDistrict}
                                            districts={districts}
                                            setDistricts={setDistricts}
                                            countryside={countryside}
                                            setCountryside={setCountryside}
                                            countrysides={countrysides}
                                            setCountrysides={setCountrysides}
                                            village={village}
                                            setVillage={setVillage}
                                            villages={villages}
                                            setVillages={setVillages}
                                        />
                                    ))}
                        </div>
                    </div>
                </form>
                <div className="create-modal__buttons pad_y_1">
                    {activeStep > 0 ? (
                        <Button
                            onClick={() => {
                                incDecActiveStep(-1);
                            }}
                            className="create-modal__button create-modal__cancel"
                        >
                            Назад
                        </Button>
                    ) : null}
                    {activeStep < steps.length ? (
                        <Button
                            onClick={() => {
                                incDecActiveStep(1);
                            }}
                            className="create-modal__button create-modal__accept"
                        >
                            Далее
                        </Button>
                    ) : null}
                    {+activeStep === 10 || matches ? (
                        <Button
                            type="submit"
                            className="create-modal__button create-modal__accept"
                        >
                            Сохранить
                        </Button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default QuestionsFamily
