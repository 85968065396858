import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  input: {
    height: 40,
  },
  header: {
    padding: `${theme.spacing(4)}px 0`,
    flexGrow: 1
  },
  header__action_wrap: {
    display: 'flex',
    alignItems: 'center'
  },
  header__title: {
    fontWeight: 700,
    fontSize: '30px',
    color: '#000',
    margin: 0,
    // marginLeft: theme.spacing(1)
  },
  header__search: {
    background: '#fff',
    padding: 0,
    '& > div': {
      borderRadius: '4px 0 0 4px',
      borderWidth: '0 !important',
      borderColor: '#fff'
    }
  },
  header__search_input: {
    height: '20px'
  },
  header__search_button: {
    background: theme.palette.lightBlue.main,
    height: '40px',
    boxShadow: 'none !important',
    borderRadius: '0 4px 4px 0',
    '&:hover': {
      background: theme.palette.blue.main
    }
  },
  header__filter_button: {
    textTransform: 'none'
  },
  header__filter_icon: {
    marginRight: theme.spacing(1)
  },
  header__add_post: {
    background: theme.palette.lightBlue.main,
    fontWeight: 600,
    height: '40px',
    textDecoration: 'none',
    '&:hover': {
      background: theme.palette.blue.main
    }
  },
  header__add_post_icon: {
    marginRight: theme.spacing(1)
  },
  modalWindow: {
    width: '100%',
    maxWidth: '500px'
  }
}));

export default useStyles;
