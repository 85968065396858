import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  Button,
  Container,
  Typography,
  Divider,
  LinearProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import JsxParse from "../common/jsx-parse";
import "../common/style.css";
import { useParams, withRouter } from "react-router-dom";
import { links } from "../../../Main/Sidebar";
import {
  getJsonThunk,
  getSchemaThunk,
  postNewSchemeThunk,
  postEditedSchema,
} from "../mainTableActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import regions from "../../../pages/regions.json";

let changedFields = {};

const CreateSignal = (props) => {
  // const [data, setData] = useState({});
  // console.log("🚀 ~ file: data", data)
  const [fieldsInfo1, setFieldsInfo1] = useState([]);
  const [fieldsInfo2, setFieldsInfo2] = useState([]);
  const [fieldsInfo3, setFieldsInfo3] = useState([]);
  const data = useSelector(
    (store) =>
      store.mainPageReducer.data.models &&
      store.mainPageReducer.data.models[0] &&
      store.mainPageReducer.data.models[0].model
  );
  const [record, setRecord] = useState(null);
  const [foundDropDownOpen, setFoundDropDownOpen] = useState(null);

  const [region, setRegion] = useState(null);
  const [district, setDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [countryside, setCountryside] = useState(null);
  const [countrysides, setCountrysides] = useState([]);
  const [village, setVillage] = useState(null);
  const [villages, setVillages] = useState([]);

  console.log("%crecord", "color: red", record);
  const fields = useSelector((state) => state.mainPageReducer.schema);
  const recordData = useSelector((state) => state.mainPageReducer.data);

  const openFoundDropDown = (event) => {
    setFoundDropDownOpen(event.currentTarget);
  };

  const closeFoundDropDown = () => {
    setFoundDropDownOpen(null);
  };

  // const selector = useSelector((state) => state.mainPageReducer);
  const dispatch = useDispatch();
  // const getSchema = (label, id) => dispatch(getSchemaThunk(id, label, id));

  const history = useHistory();
  const { id } = useParams();

  const classes = useStyles();

  const changeField = (obj, id) => {
    const temp = Object.keys(obj);
    changedFields[temp[0]] = obj[temp[0]];
  };

  // const handleInput = (e) => {
  //   const { name, value } = e.target;
  //   setData((prev) => ({ ...prev, [name]: value }));
  // };

  useEffect(() => {
    // getSchema(null, links[0].id);
    if (id && record && record.length) {
      changedFields = record[0].model;
    }

    dispatch(getSchemaThunk(links[0].id, null, links[0].id));
    dispatch(getJsonThunk("274f0c5d-2c02-43b2-8d59-77e73b4494ea"));
  }, [dispatch]);

  useEffect(() => {
    recordData.models &&
      setRecord(
        recordData.models.filter((item) => {
          return item.id === id;
        })
      );
  }, [recordData, id]);

  useEffect(() => {
    fields.models &&
      setFieldsInfo1([fields.models[1], fields.models[2], fields.models[0]]);
    fields.models &&
      setFieldsInfo2([
        fields.models[5],
        fields.models[16],
        fields.models[12],
        fields.models[14],
        fields.models[17],
        fields.models[13],
        fields.models[15],
        fields.models[6],
        fields.models[4],
        fields.models[8],
        fields.models[3],
        fields.models[7],
        fields.models[10],
      ]);
    fields.models && setFieldsInfo3([fields.models[9], fields.models[11]]);
  }, [fields]);

  // ! update labels
  useEffect(() => {
    let newFieldinfo1 = fieldsInfo1;
    if (newFieldinfo1.length) {
      // newFieldinfo1[0].label = "Имя ребенка";
      // newFieldinfo1[1].label = "Фамилия ребенка";
      // newFieldinfo1[2].label = "Отчество ребенка";
    }
    setFieldsInfo1(newFieldinfo1);

    // let newFieldinfo2 = fieldsInfo2;
    // if (newFieldinfo2.length) {
    //   newFieldinfo2[0].label = "Область/город респ. значения";
    //   newFieldinfo2[1].label = "Район/город областного значения";
    //   newFieldinfo2[2].label = "Айыл. акмак/Город рай.значения";
    //   newFieldinfo2[3].label = "Поселок/ПГТ/село";
    //   newFieldinfo2[4].label = "Территориальная единица";
    //   newFieldinfo2[5].label = "Улица";
    //   newFieldinfo2[6].label = "Дом";
    //   newFieldinfo2[7].label = "Квартира";
    //   newFieldinfo2[8].label = "№ участка";
    // }
    // setFieldsInfo2(newFieldinfo2);

    let newFieldinfo3 = fieldsInfo3;
    if (newFieldinfo3.length) {
      // newFieldinfo3[0].label = "Другие причины сигнала";
      // newFieldinfo3[1].label = "Кто сообщил";
      // newFieldinfo3[2].label = "Контактный телефон заявителя";
    }
    setFieldsInfo3(newFieldinfo3);
  }, [fieldsInfo1, fieldsInfo2, fieldsInfo3]);
  const redirects = () => {
    history.push("/table/274f0c5d-2c02-43b2-8d59-77e73b4494ea");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (id) {
    //   dispatch(
    //     postEditedSchema(
    //       id,
    //       "274f0c5d-2c02-43b2-8d59-77e73b4494ea",
    //       changedFields,
    //       redirects
    //     )
    //   );
    // } else {
    //   dispatch(
    //     postNewSchemeThunk(
    //       "274f0c5d-2c02-43b2-8d59-77e73b4494ea",
    //       changedFields,
    //       redirects
    //     )
    //   );
    // }
  };

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach(([key, value]) => {
        changedFields[key] = value;
      });
    }
  }, [data]);

  return (
    <div className='create-signal__wrapper'>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        {/* <div className="QuestionsFamily__title">Вы добавите новую запись в сигнал</div> */}
        <Container maxWidth='lg' className={classes.container}>
          {fieldsInfo1.length && fieldsInfo2.length && fieldsInfo3.length ? (
            <>
              <DialogTitle
                id='confirmation-dialog-title'
                className='form__title'
              >
                {props.match.params.id
                  ? record
                    ? "Сигнал от: " +
                      new Date(
                        record[0].model["date-kGfig9bx9lMo5FoB_ztfv"]
                      ).toLocaleString()
                    : null
                  : 'Создать новую запись в базе "Сигнал"'}
              </DialogTitle>

              <Typography className='create-signal__text' paragraph>
                Информация о ребенке
              </Typography>
              {console.log("debug", record && record.length && record[0].model)}
              {fieldsInfo1 &&
                fieldsInfo1.length &&
                fieldsInfo1.map((item, idx) => {
                  return (
                    <JsxParse
                      idx={idx}
                      item={item}
                      eventHandler={changeField}
                      initialValue={fields.labels[idx] || item.model}
                      serverData={record && record.length && record[0].model}
                    />
                  );
                })}

              {/* <TextField name='text-8CIXIAogsktREYEHEgWdu' label="Имя ребенка" variant="outlined" />
        <TextField
          name="text-8JEi6OiBlLkiasfYHNuAT"
          label="Фамилия ребенка"
          variant="outlined"
        />
        <TextField name="text-2ToGwktgnj0UuwhwqSc0X" label="Отчество ребенка" variant="outlined" /> */}

              <Divider style={{ margin: "10px 0px 0px" }} />

              <Typography className='create-signal__text' paragraph>
                Территория
              </Typography>

              {fieldsInfo2 &&
                fieldsInfo2.length &&
                fieldsInfo2.map((item, idx) => (
                  <JsxParse
                    idx={idx}
                    item={item}
                    eventHandler={changeField}
                    initialValue={fields.labels[idx] || item.model}
                    serverData={record && record.length && record[0].model}
                    regions={regions}
                    setRegion={setRegion}
                    district={district}
                    setDistrict={setDistrict}
                    districts={districts}
                    setDistricts={setDistricts}
                    countryside={countryside}
                    setCountryside={setCountryside}
                    countrysides={countrysides}
                    setCountrysides={setCountrysides}
                    village={village}
                    setVillage={setVillage}
                    villages={villages}
                    setVillages={setVillages}
                  />
                ))}

              {/* <FormControl>
          <InputLabel>Область/город респ. значения</InputLabel>
          <Select onChange={(e) => handleInput(e)} variant="outlined"></Select>
        </FormControl>
        <FormControl>
          <InputLabel>Район/город областного значения</InputLabel>
          <Select onChange={(e) => handleInput(e)} variant="outlined"></Select>
        </FormControl>
        <FormControl>
          <InputLabel>Айыл. акмак/Город рай.значения</InputLabel>
          <Select onChange={(e) => handleInput(e)} variant="outlined"></Select>
        </FormControl>
        <FormControl>
          <InputLabel>Поселок/ПГТ/село</InputLabel>
          <Select onChange={(e) => handleInput(e)} variant="outlined"></Select>
        </FormControl>
        <TextField onChange={e => handleInput(e)} label="Территориальная единица" variant="outlined" />
        <TextField onChange={e => handleInput(e)} label="Улица" variant="outlined" />
        <TextField onChange={e => handleInput(e)} label="Дом" variant="outlined" />
        <TextField onChange={e => handleInput(e)} label="Квартира" variant="outlined" />
        <TextField onChange={e => handleInput(e)} label="№ участка" variant="outlined" /> */}

              <Divider style={{ margin: "10px 0px 0px" }} />

              <Typography className='create-signal__text' paragraph>
                Причины сигнала
              </Typography>

              {fieldsInfo3 &&
                fieldsInfo3.length &&
                fieldsInfo3.map((item, idx) => (
                  <JsxParse
                    idx={idx}
                    item={item}
                    eventHandler={changeField}
                    // initialValue={fields.labels[idx] || item.model}
                    initialValue={fields.labels[idx] || item.model}
                    serverData={record && record.length && record[0].model}
                  />
                ))}

              {/* <TextField onChange={e => handleInput(e)} label="Другие причины сигнала" variant="outlined" />
        <TextField onChange={e => handleInput(e)}
          name="select-lc-hUSPQD__ACWuFhAaFo"
          label="Кто сообщил"
          variant="outlined"
        />
        <TextField onChange={e => handleInput(e)} label="Контактный номер заявителя" variant="outlined" /> */}

              {/* {
                id &&
                <div className={classes.dropDownButtonContainer}>
                  <Button
                    aria-controls="select-child-status"
                    aria-haspopup="true"
                    onClick={openFoundDropDown}
                    className={classes.dropDownButton}
                  >
                    Выбрать статус ребенка
                </Button>

                  <Menu
                    id="select-child-status"
                    anchorEl={foundDropDownOpen}
                    keepMounted
                    open={Boolean(foundDropDownOpen)}
                    onClose={closeFoundDropDown}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(postEditedSchema(
                          id,
                          "274f0c5d-2c02-43b2-8d59-77e73b4494ea",
                          {
                            ...changedFields,
                            'radio-dbb2OO3_DGTpm412g55Vd': 'нет'
                          },
                          redirects
                        ))
                      }}
                    >
                      Ребенок не найден
                  </MenuItem>

                    <MenuItem
                      onClick={() => {
                        dispatch(postEditedSchema(
                          id,
                          "274f0c5d-2c02-43b2-8d59-77e73b4494ea",
                          {
                            ...changedFields,
                            'radio-dbb2OO3_DGTpm412g55Vd': 'да'
                          },
                          redirects
                        ))
                      }}
                    >
                      Ребенок найден
                  </MenuItem>
                  </Menu>

                </div>
              } */}

              <div className='create-modal__buttons'>
                <Button
                  className='create-modal__button create-modal__cancel'
                  onClick={() => {
                    props.history.push(
                      "/table/274f0c5d-2c02-43b2-8d59-77e73b4494ea"
                    );
                  }}
                >
                  Отмена
                </Button>

                <Button
                  className='create-modal__button create-modal__accept'
                  type='submit'
                >
                  Сохранить сигнал
                </Button>
              </div>
            </>
          ) : (
            <LinearProgress />
          )}
        </Container>
      </form>
    </div>
  );
};

export default withRouter(CreateSignal);

const useStyles = makeStyles({
  container: {
    backgroundColor: "white",
    width: "1048px",
    borderRadius: "10px",
    margin: "50px 0",

    "@media (max-width: 900px)": {
      width: "100%",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
    },
  },

  dropDownButtonContainer: {
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },

  dropDownButton: {
    background: "#0e4ffa",
    color: "#fff",
    textTransform: "initial",
    borderRadius: 10,

    "&:hover": {
      background: "#0e4ffa",
    },

    "& .MuiButton-label": {
      padding: "0 8px",
    },
  },
});
